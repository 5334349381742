import React from 'react';
import {Grid, Typography} from "@mui/material";

const Clause = props => {

  // Indent clause if reference has more than one decimal point e.g. '1.1.2'
  let indent = false;
  if (props.reference) {
    if (props.reference.split('.').length > 2)
      indent = true;
  }

  return (
    <React.Fragment>
      {
        indent
        ?
        <React.Fragment>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Typography sx={{pl: props.indent ? 2 : 0}}>{props.reference}</Typography>
          </Grid>
          <Grid item xs={10}>
            {props.children}
          </Grid>
        </React.Fragment>
        :
        <React.Fragment>
          <Grid item xs={1}>
            <Typography>{props.reference}</Typography>
          </Grid>
          <Grid item xs={11}>
            {props.children}
          </Grid>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default Clause;