import React from 'react';
import {Card, CardActionArea, CardContent, CardHeader, CardMedia, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const NewsCard = props => {

  let imageURL = props.image === undefined ? '/images/placeholder.png' : props.image;

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card>
        <CardActionArea component={Link} to={`/${props.categoryID}/${props.id}`}>
          <CardMedia
            component="img"
            height="200"
            image={!props.image ? `${process.env.PUBLIC_URL}/images/news-placeholder.png` : `${process.env.PUBLIC_URL}${imageURL}`}
            alt="Image"
          />
          <CardHeader
            title={props.title}
            subheader={props.date}
          />
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default NewsCard;