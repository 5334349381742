import React from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import CategoryHeader from "../components/CategoryHeader";
import GenericCard from "../components/GenericCard";
import {BROWSE_PRODUCTS_AND_SERVICES_URL, CITYCAD_SETUP_FILE_URL, STREETSCAPE_SETUP_FILE_URL} from "../config";

export const streetscapeLongDescription = (
  <React.Fragment>

    <CategoryHeader>What is Streetscape?</CategoryHeader>

    <Grid item xs={12} md={12}>
      <Typography variant="h6" sx={{ textAlign: 'center', }}>
        Streetscape is a simple, easy to use Windows app for creating simple street designs.
      </Typography>
      <Typography variant="h6" sx={{ textAlign: 'center', }}>
          It is free of charge, quick to download and no sign-up is necessary.
      </Typography>
    </Grid>

    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center'}}>
        <Box component="img" src="/images/streetscape_intro-1.png" sx={{ width: '100%', maxWidth: 600 }} boxShadow={12} />
    </Grid>

    <CategoryHeader>Features</CategoryHeader>

    <GenericCard
      image="/images/streetscape_intro_3.png"
      title="Visualization"
      description="CityCAD offers a quick and easy way of sketching 3D urban layouts and testing ideas. Street junctions are automatically created at intersections and blocks resize if you move adjacent streets."
    />

    <GenericCard
      image="/images/streetscape_intro_6.png"
      title="Analysis"
      description="Keep track of quantities such as floor areas, unit numbers and car parking spaces. All data is updated in real time as you make design changes, making it easy to test ideas quickly."
    />

    <GenericCard
      image="/images/streetscape_intro_5.png"
      title="Communication"
      description="Discuss your ideas in real time on screen, or generate plans, reports, spreadsheets and 3D views to communicate your project ideas to team members and other stakeholders."
    />

    <CategoryHeader>Integration with CityCAD</CategoryHeader>

    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center'}}>
      <Box component="img" src="/images/streetscape_intro_8.png" sx={{ width: '100%', maxWidth: 600 }} boxShadow={12} />
    </Grid>

    <Grid item xs={12} md={6}>

      <Typography variant="h6" sx={{ pt: { xs: 1, md: 3} }}>
        Street designs created in Streetscape can be easily imported into CityCAD, and applied to streets in an information-rich 3D model.
      </Typography>
      <Typography variant="h6" sx={{ pt: { xs: 1, md: 3} }}>
        Here, you can visualize the street in 3D and add features from the CityCAD object library including electric vehicle charging points and parklets.
      </Typography>

    </Grid>

    <Grid item xs={12}>
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography paragraph>
          To get started, download the Streetscape application for Windows.
          This app is completely free of charge.
        </Typography>
        <a
          href={STREETSCAPE_SETUP_FILE_URL}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">
            Download Streetscape 2.0.1.1005 Setup.exe
          </Button>
        </a>
      </Box>
    </Grid>

  </React.Fragment>
);
