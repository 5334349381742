import React from 'react';

import { citycadLongDescription } from './citycadLongDescription';
import { assetSimLongDescription } from './assetSimLongDescription';
import { siteLayoutToolkitLongDescription } from './siteLayoutToolkitLongDescription';
import { streetscapeLongDescription } from "./streetscapeLongDescription";
import { urbanDesignDatabaseLongDescription } from "./urbanDesignDatabaseLongDescription";
import { newsItems } from "./news";
import {Box, Grid, Typography} from "@mui/material";
import convertObjectToArray from "../utilities/convertObjectToArray";

/*
 * List of links in top navbar
 */
export const pages = [
  {
    name: 'Home',
    link: ''
  },
  {
    name: 'News',
    link: 'news'
  },
  {
    name: 'CityCAD',
    link: 'solutions/citycad'
  },
  {
    name: 'Solutions',
    link: 'solutions'
  },
  // {
  //   name: 'Downloads',
  //   link: 'https://www.holisticcity.co.uk/downloads/',
  //   external: true
  // },
  {
    name: 'Store',
    link: 'store'
    // link: 'https://www.holisticcity.co.uk/client/cart.php?gid=1',
    // external: true
  },
  // {
  //   name: 'Client Portal',
  //   link: 'https://www.holisticcity.co.uk/client',
  //   external: true
  // }
];

export const getNewsItems = count => {
  // Sort by most recent
  newsItems.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  })

  return newsItems.slice(0, count);
}

export const getNewsItemCount = () => {
  return newsItems.length;
}

export const getNewsItem = id => {
  for (let i = 0; i < newsItems.length; i++) {
    if (newsItems[i].id === Number(id))
      return newsItems[i];
  }
}

/*
 * Solutions to be previewed on front page
 */
export const frontPageSolutions = [ 'citycad', 'siteLayoutToolkit', 'streetscape']; // 'streetscape'

/*
 * Main database of solutions
 */
export const solutions = {
  citycad: {
    image: '/images/cycle-friendly-neighbourhood.png',
    title: 'CityCAD',
    description: `The world's first major CAD application created specifically for conceptual urban design and city information modelling.`,
    longDescription: citycadLongDescription
  },
  streetscape: {
    image: '/images/glasgow.jpg',
    title: 'Streetscape',
    description: 'An innovative tool for quickly building and exploring street designs, available free of charge.',
    longDescription: streetscapeLongDescription
  },
  database: {
    image: '/images/barton.jpg',
    title: 'Urban Design Database',
    description: 'Search on a map for examples of urban streets, spaces, grids and housing projects.',
    longDescription: urbanDesignDatabaseLongDescription
  },
  assetsim: {
    image: '/images/philadelphia.jpeg',
    title: 'AssetSim',
    description: 'A strategic asset management and simulation technology for modelling large scale real estate portfolios.',
    longDescription: assetSimLongDescription
  },
  liveabilityAudit: {
    image: '/images/park.jpeg',
    title: 'Liveability Audit',
    description: 'A unique process for analyzing urban liveability at the macro and micro scales, using a combination of technologies.',
    longDescription: (
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
          Contact sales@holisticcity.co.uk for more information.
        </Box>
      </Grid>
    )
  },
  siteLayoutToolkit: {
    image: '/images/housing.jpeg',
    title: 'Site Layout Toolkit',
    description: 'A solution to help residential developers test the viability of new layouts with information-rich models.',
    longDescription: siteLayoutToolkitLongDescription
  },
  cityInformationModellingToolkit: {
    image: '/images/cim-toolkit.png',
    title: 'City Information Modelling Toolkit',
    description: 'A web-based viewer for visualizing and discussing CityCAD models in their real-world context.',
    longDescription: (
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
          Contact sales@holisticcity.co.uk for more information.
        </Box>
      </Grid>
    )
  },
  generativeMasterplanning: {
    image: '/images/generative-heathrow.png',
    title: 'Generative Planning Toolkit',
    description: 'Developed in partnership with SEED Spatial Solutions, this toolkit generates land use layouts with optimised value.',
    longDescription: (
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
          Contact sales@holisticcity.co.uk for more information.
        </Box>
      </Grid>
    )
  },
  streetscapeOnline: {
    image: '/images/streetscape-prototype.png',
    title: 'Streetscape 3D',
    description: 'A prototype for an online 3D version of Streetscape, for quickly testing ideas for street cross-sections.',
    longDescription: (
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center'}}>
          Contact sales@holisticcity.co.uk for more information.
        </Box>
      </Grid>
    )
  },
  podiumBlockGenerator: {
    image: '/images/podium.png',
    title: 'Podium Block Generator',
    description: 'A prototype tool for generating podium blocks from set parameters including block size, permitted FAR and parking requirements.',
    longDescription: (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography paragraph>
            This prototype tool was developed following discussions with clients in the UAE to rapidly explore designs for podium blocks.
          </Typography>
          <Typography paragraph>
            Set parameters including block size, setback, permitted FAR, unit size, allowed basements and parking requirements, and a 3D visualization will be created automatically.
          </Typography>
          <Typography paragraph>
            Parking is located in basements and podium floors, and residential units in a tower above.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
          <Box sx={{ maxWidth: '700px'}} component="img" src="/images/podium-generator.png" />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
          Contact sales@holisticcity.co.uk for more information.
        </Grid>
      </React.Fragment>
    )
  }
}

/*
 * Items for map
 */
export const mapItems = {
  cabesummerschool: {
    title: 'CABE Summer School',
    image: '/images/gateshead.jpeg',
    location: [54.966348374448664, -1.6058365774232586],
    locationDescription: 'Gateshead, UK',
    solutions: ['citycad'],
    description: 'Shortly after the launch of CityCAD in 2008, we attended the toolshop of the CABE Urban Design Summer School and helped students create information-rich CityCAD models of their schemes.'
  },
  wilkinsburg: {
    title: 'Wilkinsburg Business District Revitalization Plan',
    image: '/images/wilkinsburg.png',
    location: [40.44296513537295, -79.88226056368404],
    locationDescription: 'Wilkinsburg, PA, USA',
    solutions: ['citycad'],
    description: 'Burt Hill, an international multidisciplinary firm, provided land use planning, urban design and landscape architecture services to prepare a Business District Revitalization Plan for the borough of Wilkinburg near Pittsburgh..'
  },
  puertopaez: {
    id: 3,
    title: 'Puerto Paez Eco City',
    image: '/images/puerto-paez.jpg',
    location: [6.212947093715038, -67.45079584443839],
    locationDescription: 'Apure, Venezuela',
    solutions: ['citycad'],
    description: 'CityCAD was used to produce a preliminary conceptual masterplan of the new Puerto Paez Eco City in the Apure region in the south of Venezuela, at the confluence of the Meta and Orinoco rivers.'
  },
  towerhamlets: {
    id: 4,
    title: 'London Borough of Tower Hamlets',
    image: '/images/canary-wharf.jpeg',
    location: [51.502319101757436, -0.014714696225502302],
    locationDescription: 'London, UK',
    solutions: ['citycad'],
    description: 'The London Borough of Tower Hamlets used CityCAD in a variety of applications, including masterplanning and estate regeneration work.'
  },
  wandsworth: {
    id: 5,
    title: 'London Borough of Wandsworth',
    image: '/images/wandsworth.png',
    location: [51.455133152446834, -0.18758889089929814],
    locationDescription: 'London, UK',
    description: 'We collaborated with Rob Cowan of Urban Design Skills on the development of a unique crowdsourcing tool to collect suggestions for historic buildings, gardens and features to be included in the Local List'
  },
  glasgowuniversity: {
    id: 6,
    title: 'Glasgow University',
    image: '/images/clyde.jpg',
    location: [55.873620720879025, -4.290069497826618],
    locationDescription: 'Glasgow, UK',
    solutions: ['citycad'],
    description: 'We have been visiting Glasgow for the last few years to introduce CityCAD to development and urban design students.'
  },
  gadjahmada: {
    id: 7,
    title: 'Gadjah Mada University',
    image: '/images/yogyakarta.png',
    location: [-7.76506263687323, 110.37227609391779],
    locationDescription: 'Yogyakarta, UK',
    solutions: ['citycad'],
    description: 'In 2020 a CityCAD workshop was held at Gadjah Mada University.'
  },
  buidstudy: {
    id: 8,
    title: 'Sustainable Development and Planning',
    image: '/images/buid-liveability.png',
    location: [41.04118529324584, 28.989463136169416],
    locationDescription: 'Istanbul, Turkey',
    solutions: ['citycad'],
    description: 'At the 2015 Sustainable Development and Planning conference, a detailed CityCAD-based sustainability evalution of a major project in Dubai was presented by Nada El-Bana, Samy Eselim and Hanan Taleb.'
  },
  auckland: {
    id: 9,
    title: 'Urban Design Forum',
    image: '/images/auckland.jpeg',
    location: [-36.852993822743976, 174.76317125272465],
    locationDescription: 'Auckland, NZ',
    solutions: ['citycad'],
    description: 'CityCAD was presented at the Urban Design Forum event at Auckland Town Hall in January 2011.'
  },
  mysuru: {
    id: 10,
    title: 'Mysuru and Madurai',
    image: '/images/mysore.jpeg',
    location: [12.310301359879055, 76.64118022911515],
    locationDescription: 'Mysuru, India',
    solutions: ['citycad'],
    description: 'We worked with Atkins plc on the development of an advanced masterplan carbon analysis tool as part of their Carbon Critical tools programme. This was used to carry out detailed evaluations of the cities of Mysuru in Karnataka and Madurai in Tamil Nadu.'
  },
  healthcaredesignconference: {
    id: 11,
    title: 'Healthcare Design Conference',
    image: '/images/national-harbor.jpeg',
    location: [38.780598819893925, -77.01741040263418],
    locationDescription: 'Washington, DC, USA',
    solutions: ['citycad', 'assetsim'],
    description: `Working with the Children's Hospital of Philadelphia, we developed an advanced scenario planning tool for large real estate portfolios. This was presented at the 2015 Healthcare Design Conference in Washington, DC.`
  },
  toronto: {
    id: 12,
    title: 'IVG Passages Competition',
    location: [43.65866467858085, -79.39809853484653],
    locationDescription: 'Toronto, Canada',
    description: `As part of a team led by Matthew Springett of MSA Architecture, we participated in a workshop to explore urban design ideas for suburban sites in Toronto.`
  },
  imredd: {
    id: 13,
    title: 'CityCAD and City Information Modelling Workshops',
    image: '/images/imredd.png',
    location: [43.68049541217478, 7.20314751409971],
    locationDescription: 'Nice, France',
    description: `For the last few years we have been working with students at the Mediterranean Institute of Risk, Environment and Sustainable Development at the Université Côte d'Azur.`
  },
  brookes: {
    id: 14,
    title: 'Oxford Brookes University',
    image: '/images/brookes.jpg',
    location: [51.75536057207412, -1.2244048682147473],
    locationDescription: 'Oxford, UK',
    solutions: ['citycad'],
    description: `We have been working with urban design students at Brookes for the last few years, and have also been working with Jon Cooper on the development of a new view analysis technology called ViewCue.`
  },
  letchworth: {
    id: 15,
    title: 'Letchworth Garden City',
    image: '/images/letchworth.png',
    location: [51.99828417414309, -0.2253660039554581],
    locationDescription: 'Letchworth Garden City, UK',
    solutions: ['citycad'],
    description: `We collaborated with EcoResponsive Environments to build a city information model of a competition-winning scheme for a northern extension to the world-famous Garden City in Hertfordshire.`
  },
  heathpark: {
    id: 16,
    title: 'Heath Park',
    location: [53.32450066732039, -2.7364004386895004],
    locationDescription: 'Runcorn, UK',
    solutions: ['citycad'],
    description: `We collaborated with EcoResponsive Environments to build a city information model of a redevelopment of the Heath Park area in Runcorn, including a new vertical farming proposal.`
  },
  launch: {
    id: 17,
    title: 'CityCAD Launch',
    location: [51.534236026620206, -0.1202556041210943],
    locationDescription: 'London, UK',
    solutions: ['citycad'],
    description: `CityCAD was launched in June 2008 at an event at the London Canal Museum.`
  },
  modellingworld: {
    id: 18,
    title: 'Modelling World Conference',
    location: [51.483916722960984, -0.1164408941613805],
    locationDescription: 'London, UK',
    solutions: ['citycad'],
    description: `CityCAD and related city information modelling technologies were presented at the Landor Links Modelling World conferences in 2015 and 2016.`
  },
  southbank: {
    id: 19,
    title: 'South Bank University',
    location: [51.49883750197912, -0.10128585849313648],
    locationDescription: 'London, UK',
    solutions: ['citycad'],
    description: `In 2008 we worked with urban design students at London South Bank University.`
  },
  winchester: {
    id: 20,
    title: 'National Urban Design Conference',
    image: '/images/winchester.jpg',
    location: [51.061294817556856, -1.3103315832823685],
    description: `In 2018 we were pleased to support the Urban Design Group annual conference in Winchester and present on the subject of Smart Cities.`
  },
  hemelhempstead: {
    id: 21,
    title: 'Henry Wells Square',
    image: '/images/hemel.jpg',
    location: [51.77291256750451, -0.45759556530435336],
    locationDescription: 'Hemel Hempstead, UK',
    solutions: ['citycad'],
    description: `In 2013 we worked with Sue McGlynn on a neighbourhood planning project to explore and test community ideas for a local centre using CityCAD.`
  },
  technologyspaceandplace: {
    id: 22,
    title: 'Technology, Space and Place',
    location: [51.5199731975459, -0.10331839821791074],
    locationDescription: 'London, UK',
    solutions: ['citycad'],
    description: `In 2008 we participated in the Technology, Space and Place event at the Cowcross Street Gallery in London.`
  },
  gulf: {
    id: 23,
    title: 'UAE and Bahrain',
    image: '/images/abu-dhabi.jpg',
    location: [24.999451919547813, 53.45586397308925],
    locationDescription: 'UAE and Bahrain',
    solutions: ['citycad'],
    description: `We have undertaken several projects in the Gulf region including online street design tools adapted from our Streetscape technology, as well as CityCAD presentation and training workshops.`
  },
  heathrow: {
    id: 24,
    title: 'Heathrow Airport Study',
    image: '/images/generative-heathrow-slide.png',
    location: [51.47209327045153, -0.4543113299784225],
    locationDescription: 'UAE and Bahrain',
    solutions: ['citycad'],
    description: `Working in partnership with Spiros Karakostas of Seed Spatial Optimization, this project in 2013 used an experimental generative masterplanning technology in a hypothetical redevelopment of the Heathrow Airport site.`
  },
  medicalcity: {
    id: 25,
    title: 'Medical City Salon',
    image: '/images/placeholder.png',
    location: [51.523261226799676, -0.08397830654675131],
    locationDescription: 'Scrutton Street, London',
    solutions: [],
    description: `We collaborated with the This Is Not A Gateway festival of urbanism on this informal discussion event looking at the links between medicine and urbanism, and asking what city planners might be able to learn from medical concepts such as 'informed choice' and the need for evidence based practice.`,
    longDescription: (
      <Grid item xs={12}>
        <Typography paragraph>
          Synopsis from the TINAG website:
        </Typography>
        <Typography paragraph>
          The number one cause of death amongst children in the developing world is not famine or war, but respiratory illness due to urban pollution.
          In an rapidly increasing urbanised world there is an urgency to address the complexities between cities and public health.
          Should the medical fraternity be the future builders of cities?
        </Typography>
        <Typography paragraph>
          'The Medical City' was an informal discussion / ideas event kindly hosted by Public Works, 2-8 Scrutton Street, London EC2A 4RT
        </Typography>
      </Grid>
    )
  },
  stockholm: {
    id: 24,
    title: 'Carbon Critical Masterplan pilot',
    image: '/images/stockholm.jpg',
    location: [59.354888, 18.057489],
    locationDescription: 'Stockholm',
    solutions: ['citycad'],
    description: `A pilot study using the CityCAD / Atkins Carbon Critical Masterplanning solution to assess the carbon emissions from constructing a new academic campus in Albano, Stockholm.`,
    longDescription: (
      <Grid item xs={12}>
        <Typography paragraph>
          Read more about this project in a <a target="_blank" href="https://stud.epsilon.slu.se/7171/">thesis by Elias Prokofiev (in Swedish)</a>
        </Typography>
      </Grid>
    )
  }
}

export const getFrontPageSolutionArray = () => {
  const solutionArray = convertObjectToArray(solutions);

  const frontPageSolutionArray = solutionArray.filter(solution => {
      if (frontPageSolutions.includes(solution.id))
        return true;
      else
        return false;
    }
  )

  return frontPageSolutionArray;
}