import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider } from "@mui/material";
// import { Helmet } from "react-helmet";

import AboutUs from "./pages/AboutUs";
import CityCAD from "./pages/CityCAD";
import Footer from "./components/Footer";
import Homepage from "./pages/Homepage";
import Map from "./pages/Map";
import MapItemDescription from "./pages/MapItemDescription";
import Navbar from "./components/Navbar";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./utilities/ScrollToTop";
import ServicesTerms from "./pages/ServicesTerms";
import Solution from "./pages/Solution";
import Solutions from "./pages/Solutions";
import Store from "./pages/Store";
import { theme, colours } from "./config.js";
import WebsiteTerms from "./pages/WebsiteTerms";
import News from "./pages/News";
import NewsArticle from "./pages/NewsArticle";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <CssBaseline />
        <Navbar />
        <Container sx={{backgroundColor: colours.white}}>
          <Routes>
            <Route path={'/'} exact element={<Homepage />} />
            <Route path={'/about'} exact element={<AboutUs />} />
            <Route path={'/citycad'} exact element={<CityCAD />} />
            <Route path={'/solutions'} exact element={<Solutions />} />
            <Route path={'/solutions/:id'} element={<Solution />} />
            <Route path={'/map'} exact element={<Map />} />
            <Route path={'/map/:id'} element={<MapItemDescription />} />
            <Route path={'/news'} exact element={<News />} />
            <Route path={'/news/:id'} exact element={<NewsArticle />} />
            <Route path={'/website-terms'} exact element={<WebsiteTerms />} />
            <Route path={'/services-terms'} exact element={<ServicesTerms />} />
            <Route path={'/store'} exact element={<Store />} />
            <Route path={'/privacy-policy'} exact element={<PrivacyPolicy />} />
            <Route path={'/about'} exact element={<AboutUs />} />
          </Routes>
        </Container>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
