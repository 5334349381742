import React from 'react';
import {Box, Breadcrumbs, Button, Grid, Typography} from "@mui/material";
import { Link } from "react-router-dom";
import CategoryHeader from "../components/CategoryHeader";
import {BROWSE_PRODUCTS_AND_SERVICES_URL, CITYCAD_SETUP_FILE_URL} from "../config";

const Store = () => {
  return(
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Breadcrumbs arial-label="breadcrumb" sx={{ mt: 2 }}>
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="text.primary">Store</Typography>
        </Breadcrumbs>
      </Grid>

      <Grid item xs={12}>
        <CategoryHeader>CityCAD Licensing</CategoryHeader>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box sx={{ p:3, width: '100%', textAlign: 'center' }}>
          <Box
            sx={{ width: '100%', maxWidth: '800px'}}
            component="img"
            src="/images/cycle-friendly-neighbourhood.png"
          />
        </Box>
      </Grid>

      <Grid xs={12} md={6}>
        <Box sx={{ p: 3 }}>
          <Typography paragraph>
            To get started, download the CityCAD application for Windows.
            This includes a built-in 30-day free trial.
          </Typography>
          <a
            href={CITYCAD_SETUP_FILE_URL}
            style={{ textDecoration: 'none' }}
          >
            <Button variant="contained">
              Download CityCAD 3.2.1.726 x64 Setup.exe
            </Button>
          </a>
        </Box>
        <Box sx={{ p: 3}}>

          <Typography paragraph>
            A range of licence options are available including free academic licences, individual and team licences.
            Please contact our team at support@citycadtechnologies.co.uk if any further questions.
          </Typography>
          {/*<a*/}
          {/*  href={BROWSE_PRODUCTS_AND_SERVICES_URL}*/}
          {/*  style={{ textDecoration: 'none' }}*/}
          {/*  sx={{mt: 3}}*/}
          {/*>*/}
          {/*  <Button variant="contained">*/}
          {/*    View Licence options*/}
          {/*  </Button>*/}
          {/*</a>*/}
        </Box>
      </Grid>

    </Grid>
  )
}

export default Store;