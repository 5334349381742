import React from 'react';
import {Breadcrumbs, Grid, Typography} from "@mui/material";

import ProjectMap from "../components/ProjectMap";
import {Link} from "react-router-dom";

const Map = () => {
  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Breadcrumbs arial-label="breadcrumb" sx={{ mt: 2 }}>
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="text.primary">Projects, Case Studies and Events</Typography>
        </Breadcrumbs>
      </Grid>

      <ProjectMap />

    </Grid>
  )
}

export default Map;