import React from 'react';
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";

import CategoryHeader from "../components/CategoryHeader";
import ProjectMap from "../components/ProjectMap";
import SolutionCard from "../components/SolutionCard";
import TitleCard from "../components/home/TitleCard";
import { getFrontPageSolutionArray } from "../data/data";

const Homepage = () => {
  const frontPageSolutions = getFrontPageSolutionArray();

  return (
    <Grid container spacing={2} maxWidth="lg" sx={{ justifyContent: 'center', p: 2 }}>
      <Grid item xs={12} sx={{ mt: 3 }}>
        <TitleCard />
      </Grid>

      <CategoryHeader>
        Featured Solutions
      </CategoryHeader>
      {
        frontPageSolutions.map(solution => {
          return (
            <SolutionCard key={solution.id} categoryID="solutions" {...solution} />
          )
        })
      }
      <Grid item sm={12} sx={{ textAlign: 'center'}}>
        <Link
          to={`/solutions`}
          style={{ textDecoration: 'none', marginLeft: '10px' }}
        >
          <Button variant="contained">
            View all solutions
          </Button>
        </Link>
      </Grid>

      <CategoryHeader>Projects, Case Studies and Events</CategoryHeader>

      <Grid item sm={12} sx={{ textAlign: 'center'}}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="body1" paragraph>
            If you have an interesting project to share please contact us at info@holisticcity.co.uk and we can add you to the map
          </Typography>
        </Box>
      </Grid>

      <ProjectMap />

    </Grid>
  )
}

export default Homepage;