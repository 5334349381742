import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import CategoryHeader from "../components/CategoryHeader";
import GenericCard from "../components/GenericCard";
import {CITYCAD_SETUP_FILE_URL} from "../config";

export const citycadLongDescription = (
  <React.Fragment>

    <CategoryHeader>What is CityCAD?</CategoryHeader>

    <Grid item xs={12} md={6}>
      <Typography
        variant="h6"
        sx={{ pt: { xs: 1, md: 3}}}
      >
        CityCAD is a proven city information modelling solution for quickly exploring, testing and communicating urban masterplans.
      </Typography>
      <Typography
        variant="h6"
        sx={{ pt: { xs: 1, md: 3} }}
      >
        Unlike other CAD and GIS software, it was created for urban design from the beginning, and makes it easy to create 3D models using tried and tested building types and urban typologies.
      </Typography>
    </Grid>

    <Grid item xs={12} md={6}>
      <img src="/images/public-spaces.png" style={{ width: '100%' }} />
    </Grid>

    <CategoryHeader>What can CityCAD do?</CategoryHeader>

    <GenericCard
      image="/images/explore.png"
      title="Design"
      description="CityCAD offers a quick and easy way of sketching 3D urban layouts and testing ideas. Street junctions are automatically created at intersections and blocks resize if you move adjacent streets."
    />

    <GenericCard
      image="/images/test.png"
      title="Analysis"
      description="Keep track of quantities such as floor areas, unit numbers and car parking spaces. All data is updated in real time as you make design changes, making it easy to test ideas quickly."
    />

    <GenericCard
      image="/images/communicate.jpg"
      title="Communication"
      description="Discuss your ideas in real time on screen, or generate plans, reports, spreadsheets and 3D views to communicate your project ideas to team members and other stakeholders."
    />

    <CategoryHeader>Why CityCAD?</CategoryHeader>

    <GenericCard
      image="/images/sauchiehall.jpg"
      title="Best practice"
      description="Best practice in urban design can be elusive, but CityCAD was designed from scratch as a conceptual urban modelling tool. Tried-and-tested block typologies and street types are built into the software and a growing library of components will offer a short-cut to best practice in your masterplan designs."
    />

    <GenericCard
      image="/images/collaboration.jpg"
      title="Collaboration"
      description="Collaboration across specialisms is essential to successful placemaking. CityCAD was the first major CAD solution to bring together spatial design, planning, environmental and financial analysis. Built-in cloud storage capabilities make it easier than ever before to collaborate on information-rich city models."
    />

    <GenericCard
      image="/images/engagement.jpg"
      title="Engagement"
      description="CityCAD is a proven way of explaining complex planning issues to both technical stakeholders and non-specialist audiences. Along with our other technologies it will help you communicate a vision, discuss options, predict benefits, report to elected representatives and deliver quality design."
    />

    <GenericCard
      image="/images/tram.png"
      title="Decarbonisation"
      description="Analysis of operational and embodied carbon has been an integral part of CityCAD since its launch in 2008, and today it can help you assess the environmental impact of your masterplan."
    />

    <GenericCard
      image="/images/speed.jpeg"
      title="Respond quickly to change"
      description="CityCAD helps you exploit opportunities and react quickly to a constantly changing environment by testing a large number of options in a short space of time, and with greater confidence."
    />

    <GenericCard
      image="/images/shard.jpeg"
      title="Competition"
      description="CityCAD's focus on best practice in urban design and its unique liveability analysis capabilities can help give your city a competitive advantage when attracting people and organisations."
    />

    <CategoryHeader>Get Started</CategoryHeader>

    <Grid item xs={12}>
      <Typography
        variant="h6"
        sx={{ pt: { xs: 1, md: 3} }}
      >
        CityCAD comes with a built-in 30-day trial. For more information please visit our store.
      </Typography>
      <Typography
        variant="h6"
        sx={{ pt: { xs: 1, md: 3} }}
      >
        <a
          href={CITYCAD_SETUP_FILE_URL}
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained">
            Download CityCAD 3.2.1.726 x64 Setup.exe for Windows (165MB)
          </Button>
        </a>
      </Typography>
    </Grid>



  </React.Fragment>
);
