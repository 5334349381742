import React from 'react';
import {Card, CardActionArea, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const SolutionCard = props => {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card>
        <CardActionArea component={Link} to={`/${props.categoryID}/${props.id}`}>
          <CardMedia
            component="img"
            height="200"
            image={`${process.env.PUBLIC_URL}${props.image}`}
            alt="Image"
          />
          <CardContent>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', pt: 1 }}
            >
              {props.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ pt: { xs: 2, md: 3} }}
            >
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default SolutionCard;