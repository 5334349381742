import React from 'react';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { mapItems, solutions } from "../data/data";
import { Breadcrumbs, Button, Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import PageBreadcrumbs from "../components/PageBreadcrumbs";

const MapItemDescription = props => {
  let { id } = useParams();
  let mapItem = mapItems[id];

  if (mapItem === undefined) {
    return (
      <p>Sorry cannot find map item description</p>
    )
  }

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <PageBreadcrumbs
          categoryID="map"
          categoryTitle="Projects, Case Studies and Events"
          pageTitle={mapItem.title}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardMedia
            component="img"
            sx={{ height: { xs: 200, md: 300} }}
            image={mapItem.image}
            alt=""
          />
          <CardContent>
            <Typography variant="h3" sx={{ pt: 1, pb: 1 }}>
              {mapItem.title}
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {mapItem.description}
            </Typography>
            <Typography variant="body1" component="p" sx={{ mt: 1 }}>
              Related solutions: {mapItem.solutions.map(technology => {
                return (
                  <Link
                    key={technology}
                    to={`/solutions/${technology}`}
                    style={{ textDecoration: 'none', marginLeft: '10px' }}
                  >
                    <Button variant="contained">
                      {solutions[technology].title}
                    </Button>
                  </Link>
                )})}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {mapItem.longDescription}
    </Grid>
  )
}

export default MapItemDescription;