import React from 'react';
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CategoryHeader from "../components/CategoryHeader";

const AboutUs = () => {
  return(
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Breadcrumbs arial-label="breadcrumb" sx={{ mt: 2 }}>
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="text.primary">About</Typography>
        </Breadcrumbs>
      </Grid>

      <Grid item xs={12}>
        <CategoryHeader>About CityCAD Technologies</CategoryHeader>
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Box
            sx={{ width: '100%', maxWidth: '800px'}}
            component="img"
            src="/images/graysinnroad.jpg"
          />
        </Box>
        <Typography paragraph>
          We are a software company and technical consultancy based in London, UK.
        </Typography>
        <Typography paragraph>
          Established in 2005, our aim is to produce digital solutions to help urban planners, designers and developers create sustainable, liveable and resilient cities more efficiently and more effectively.
        </Typography>
        <Typography paragraph>
          We launched our main product, CityCAD, in 2008. This was the world's first major CAD application to bring together 3D spatial urban planning with analysis of social, environmental and economic sustainability.
        </Typography>
        <Typography paragraph>
          In February 2022 we changed our name from <Typography component="span" sx={{ fontStyle: 'italic'}}>Holistic City Limited</Typography> to <Typography component="span" sx={{ fontStyle: 'italic'}}>CityCAD Technologies</Typography>.
        </Typography>
      </Grid>

    </Grid>
  )
}

export default AboutUs;