import React from 'react';
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { colours, COMPANY_NAME } from "../config";

const Footer = () => {
  return (
    <Box
      spacing={2}
      sx={{
        mt: 3,
        backgroundColor: colours.medGrey,
        color: colours.white,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid container maxWidth="xl">
        <Grid item xs={12} md={4}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              FIND US
            </Typography>
            <Typography variant="body1" paragraph sx={{ pt: 1 }}>
              85 Great Portland Street<br />
              First Floor<br />
              London<br />
              W1W 7LT
            </Typography>
            <Typography variant="body1" paragraph sx={{ pt: 1 }}>
              info@citycadtechnologies.co.uk
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '0.7em'}}>
              Copyright &copy; 2005-{new Date().getFullYear()} {COMPANY_NAME} <br />
              (formerly Holistic City Limited). All rights reserved.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ p: 3 }}>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              COMPANY
            </Typography>
            <Button component={Link} sx={{ color: 'white' }} to={'/website-terms'}>
              Website terms of use
            </Button>
            <Button component={Link} sx={{ color: 'white' }} to={'/services-terms'}>
              Services terms and conditions
            </Button>
            <Button component={Link} sx={{ color: 'white' }} to={'/privacy-policy'}>
              Privacy policy
            </Button>
            <Button component={Link} sx={{ color: 'white' }} to={'/about'}>
              About Us
            </Button>
            <Button component={Link} sx={{ color: 'white' }} to={'/news'}>
              News
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer;