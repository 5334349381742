import React from 'react';
import { solutions } from "../data/data";
import SolutionCard from "../components/SolutionCard";
import { Grid } from "@mui/material";
import PageBreadcrumbs from "../components/PageBreadcrumbs";

const Solutions = () => {

  let solutionSummaries = [];
  for (const id in solutions) {
    solutions[id].id = id;
    solutionSummaries.push(solutions[id])
  }

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <PageBreadcrumbs
          categoryID="solutions"
          categoryTitle="Solutions"
        />
      </Grid>
      {
        solutionSummaries.map(solution => {
          return (
            <SolutionCard key={solution.id} categoryID="solutions" {...solution} />
          )
        })
      }
    </Grid>
  )
}

export default Solutions;