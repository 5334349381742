import React, { useState, useEffect } from 'react';
import {Button, Grid} from "@mui/material";

import { DateTime } from "luxon/build/es6/luxon";

import PageBreadcrumbs from "../components/PageBreadcrumbs";
import CategoryHeader from "../components/CategoryHeader";
import { Typography } from "@mui/material";
import NewsCard from "../components/NewsCard";
import { getNewsItems, getNewsItemCount } from "../data/data";

const News = () => {

  const [items, setItems] = useState([]);
  const [itemsDisplayed, setItemsDisplayed] = useState(60);
  const itemCount = getNewsItemCount();

  useEffect(() => {
    setItems(getNewsItems(itemsDisplayed));
  }, [itemsDisplayed])

  const showMoreItems = prevState => {
    return prevState + 3 < itemCount ? prevState + 3 : itemCount;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageBreadcrumbs
          categoryID="news"
          categoryTitle="News"
        />
      </Grid>
      <CategoryHeader>News</CategoryHeader>
      {
        items.map((item, index) => {
          return (
            <NewsCard
              key={index}
              id={item.id}
              categoryID="news"
              image={item.image}
              title={item.title}
              date={DateTime.fromISO(item.date).toLocaleString(DateTime.DATE_MED)}
              description={item.content}
            />
          )
        })
      }


      <Grid item sm={12} sx={{ textAlign: 'center'}}>
        {
          itemsDisplayed < itemCount
          ?
          <React.Fragment>
            <Typography paragraph>Showing most recent {itemsDisplayed} of {itemCount} news items</Typography>
            <Button variant="contained" onClick={() => { setItemsDisplayed(showMoreItems)}}>
              Show more
            </Button>
          </React.Fragment>
          :
          <Typography paragraph>All news items are shown</Typography>
        }
      </Grid>


    </Grid>
  )
}

export default News;