import React from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import CategoryHeader from "../components/CategoryHeader";
import { Link } from "react-router-dom";

export const urbanDesignDatabaseLongDescription = (
  <React.Fragment>

    <Grid item xs={12} md={12}>
      <Typography variant="h6" sx={{ py: 2 }}>
        The urban design database is a free tool for urban designers, planners, engineers and architects (and anyone else who is interested) who want to quickly find real-world examples of urban streets, spaces, housing projects, street grids and junctions.
      </Typography>
    </Grid>

    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center'}}>
      <Box component="img" src="/images/database.png" sx={{ width: '100%', maxWidth: 800 }} boxShadow={12} />
    </Grid>

    <CategoryHeader>How do you search?</CategoryHeader>

    <Grid item xs={12} md={12}>
      <Typography variant="h6" sx={{ mt: 1 }}>
        Simply type a query at the top and press enter to search.
      </Typography>
      <Typography variant="body1" component="p" sx={{ pt: 2 }}>
        You can search according to number values, tags, location, or a combination of these.
      </Typography>
      <Typography variant="body1" component="p" sx={{ pt: 2 }}>
        For example, you can type 'streets about 12m wide', 'housing projects next to water' or 'circular urban spaces in Italy'
      </Typography>
      <Typography variant="body1" component="p" sx={{ pt: 2 }}>
        You can also use the following tags - 'cafes', 'trees', 'bus lane', 'cycle lane', 'waterside', 'railway', 'trams', 'retail' and 'fountain'.
        For example you could search for 'urban spaces with trams trees and a fountain'
      </Typography>
    </Grid>

    <Grid item xs={12}>
      <Box sx={{ display: 'flex', justifyContent: 'center'}}>
        <Button onClick={() => { window.open('https://www.holisticcity.co.uk/database', '_blank')}}>
          Open Urban Design Database
        </Button>
      </Box>
    </Grid>

  </React.Fragment>
);
