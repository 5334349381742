import React from 'react';
import { Link } from "react-router-dom";
import { Breadcrumbs, Grid, Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, Typography } from "@mui/material";

import {COMPANY_ADDRESS, COMPANY_NAME, PRIVACY_EMAIL} from "../config";
import CategoryHeader from "../components/CategoryHeader";
import Clause from "../components/Clause";

const createData = (type, purpose, justification) => {
  return { type, purpose, justification }
}

const contractJustification = 'It’s necessary for the performance of a contract with you';
const interestJustification = 'It’s necessary for a legitimate interest, having weighed this against competing interests';

const informationTypes = [
  createData('Email address', 'We ask for this to fulfill your request for a free trial, or order of a subscription or other service, and to verify your identity when you log in to our website.', contractJustification),
  createData('Name', 'We ask for this to verify your identity when we need to contact each other for account reasons and to address you properly when contacting you.', contractJustification),
  createData('Address', 'We ask for this to verify your identity if we need to contact you and to help us understand the location of visitors to our site and users of our services in order to inform development of future services.', interestJustification),
  createData('Organization', 'We ask for this to help analyse the organizations who are interested in our services, in order to inform the development of future services.', interestJustification),
  createData('EU VAT Number', 'We ask for this to correctly apply VAT rates when processing orders', contractJustification),
  createData('How did you hear about us', 'We ask for this to help us analyse how people came to hear about our services, in order to inform future marketing operations. (This information is voluntary and you do not have to provide it when ordering services).', interestJustification)
]

const PrivacyPolicy = () => {
  return(
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Breadcrumbs arial-label="breadcrumb" sx={{ mt: 2 }}>
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="text.primary">
            Privacy Policy
          </Typography>
        </Breadcrumbs>
        {/*/>*/}
      </Grid>

      <CategoryHeader>Privacy Policy</CategoryHeader>

      <Grid item xs={12}>
        <Typography paragraph sx={{ fontWeight: 'bold'}}>
          Our new privacy policy took effect from 24 May 2018. We understand that use of your data requires your trust.
          We are committed to the highest standards of data privacy and will only use your information for clearly described purposes and in accordance with your data protection rights.
        </Typography>
        <ul>
          <li>Updated 24 May 2018 (GDPR requirements)</li>
          <li>Updated 29 January 2019 (Registered office address changed)</li>
          <li>Updated 9 March 2022 (Company name and registered office address changed)</li>
        </ul>
        <Typography paragraph>
          The websites www.citycad.co.uk, www.holisticcity.co.uk, citycad-help.appspot.com and www.cimtoolkit.co.uk (‘Website’) are provided by {COMPANY_NAME} (‘we’/’us’/’our’).
          In doing so, we may be in a position to receive and process personal information relating to you.
          As the controller of this information, we’re providing this Privacy Policy (‘Policy’) to explain our approach to personal information.
          This Policy forms part of our Terms and conditions, which governs the use of this Website.
        </Typography>
        <Typography paragraph>
          We intend only to process personal information fairly and transparently as required by data protection law including the General Data Protection Regulation (GDPR).
          In particular, before obtaining information from you we intend to alert you to this Policy, let you know how we intend to process the information and (unless processing is necessary for at least one of the 5 reasons outlined in clause 2 below) we’ll only process the information if you consent to that processing.
          The GDPR also defines certain ‘special categories’ of personal information that’s considered more sensitive.
          These categories require a higher level of protection, as explained below.
        </Typography>
        <Typography paragraph>
          Of course, you may browse parts of this Website without providing any information about yourself and without accepting cookies.
          In that case, it’s unlikely we’ll possess and process any information relating to you.
        </Typography>
        <Typography paragraph>
          We’ll start this Policy by setting out the conditions we must satisfy before processing your data.
          However, you may wish to skip to clause 4, which summarizes what we intend to collect.
          The Policy also explains some of the security measures we take to protect your personal information, and tells you certain things we will or won’t do.
          You should read this Policy in conjunction with the Terms and conditions.
        </Typography>
        <Typography paragraph>
          Sometimes, when you take a new service or product from us, or discuss taking a new service or product but decide against, we might wish to provide you with further information about similar services or products by email or other written electronic communication.
          In that situation, we will always give you the opportunity to refuse to receive that further information and if you change your mind please let us know.
          We’ll endeavour to remind you of your right to opt-out on each occasion that we provide such information.
        </Typography>
      </Grid>

      <Clause reference="1">
        <Typography>Identity and contact details</Typography>
      </Clause>
        <Clause reference="1.1">
          <Typography>Registered number: 5649954</Typography>
        </Clause>
        <Clause reference="1.2">
          <Typography>Registered office: {COMPANY_ADDRESS}</Typography>
        </Clause>
        <Clause reference="1.3">
          <Typography>{PRIVACY_EMAIL}</Typography>
        </Clause>

      <Clause reference="2">
        <Typography>When we're allowed to collect information from you</Typography>
      </Clause>
        <Clause>
          <Typography>We will only collect personal information relating to you if one of the following conditions have been satisfied:</Typography>
        </Clause>
        <Clause reference="2.1">
          <Typography>You have clearly told us that you are content for us to collect that information for the certain purpose or purposes that we will have specified.</Typography>
        </Clause>
        <Clause reference="2.2">
          <Typography>The processing is necessary for the performance a contract that we have with you.</Typography>
        </Clause>
        <Clause reference="2.3">
          <Typography>The processing is necessary so that we can comply with the law.</Typography>
        </Clause>
        <Clause reference="2.4">
          <Typography>The processing is necessary to protect someone’s life.</Typography>
        </Clause>
        <Clause reference="2.5">
          <Typography>The processing is necessary for performance of a task that’s in the public interest.</Typography>
        </Clause>
        <Clause reference="2.6">
          <Typography>The processing is necessary for our or another’s legitimate interest – but in this case, we’ll balance those interests against your interests.</Typography>
        </Clause>

      <Clause reference="3">
        <Typography>How to consent</Typography>
      </Clause>
        <Clause reference="3.1">
          <Typography>
            At the point of collecting the information, we’ll endeavour to explain how we intend to use the information and which of these purposes apply.
            If we rely on consent, we’ll provide you with the opportunity to tell us that you’re happy to provide the information.
          </Typography>
        </Clause>
        <Clause reference="3.2">
          <Typography>
            If at any point in time you change your mind and decide that you don’t consent, please let us know and we’ll endeavour to stop processing your information in the specified manner, or we’ll delete your data if there is no continuing reason for possessing it.
          </Typography>
        </Clause>
        <Clause reference="3.3">
          <Typography>If you don’t consent to a particular bit of processing, we’ll endeavour to ensure that the Website and our service continue to operate without the need for that information.</Typography>
        </Clause>

      <Clause reference="4">
        <Typography>Information we expect to collect from you</Typography>
      </Clause>
        <Clause reference="4.1">
          <Typography>We envisage asking for the following types of information from you:</Typography>
          <TableContainer>
            <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Information type</TableCell>
                  <TableCell align="center">Purpose and related details</TableCell>
                  <TableCell align="center">Justification</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {informationTypes.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.type}
                    </TableCell>
                    <TableCell align="left">{row.purpose}</TableCell>
                    <TableCell align="left">{row.justification}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Clause>
        <Clause reference="4.2">
          <Typography>We may collect personal information about you from a number of sources, including the following:</Typography>
        </Clause>
          <Clause reference="4.2.1">
            <Typography>From you when you agree to take a service or product from us, in which case this may include your contact details, date of birth, how you will pay for the product or service and your bank details.</Typography>
          </Clause>
          <Clause reference="4.2.2">
            <Typography>From you when you contact us with an enquiry or in response to a communication from us, in which case, this may tell us something about how you use our services.</Typography>
          </Clause>
          <Clause reference="4.2.3">
            <Typography>From documents that are available to the public, such as the electoral register.</Typography>
          </Clause>
          <Clause reference="4.2.4">
            <Typography>From third parties to whom you have provided information with your consent to pass it on to other organisations or persons – when we receive such information we will let you know as soon as is reasonably practicable.</Typography>
          </Clause>
        <Clause reference="4.3">
          <Typography>If you refuse to provide information requested, then if that information is necessary for a service we provide to you we may need to stop providing that service.</Typography>
        </Clause>
        <Clause reference="4.4">
          <Typography>
            At the time of collecting information, by whichever method is used, we’ll endeavour to alert you and inform you about our purposes and legal basis for processing that information, as well as whether we intend to share the information with anyone else or send it outside of the European Economic Area.
            If at any point you think we’ve invited you to provide information without explaining why, feel free to object and ask for our reasons.
          </Typography>
        </Clause>

      <Clause reference="5">
        <Typography>Using your personal information</Typography>
      </Clause>
        <Clause reference="5.1">
          <Typography>
            Data protection, privacy and security are important to us, and we shall only use your personal information for specified purposes and shall not keep such personal information longer than is necessary to fulfil these purposes.
            The following are examples of such purposes.
            We have also indicated below which GDPR justification applies, however it will depend on the circumstances of each case.
            At the time of collecting we will provide further information, and you may always ask for further information from us.
          </Typography>
        </Clause>
          <Clause reference="5.1.1">
            <Typography>
              To help us to identify you when you contact us.
              This will normally be necessary for the performance our contract.
            </Typography>
          </Clause>
          <Clause reference="5.1.2">
            <Typography>
              To help us to identify accounts, services and/or products which you could have from us or selected partners from time to time.
              We may do this by automatic means using a scoring system, which uses the personal information you’ve provided and/or any information we hold about you and personal information from third party agencies (including credit reference agencies).
              We will only use your information for this purpose if you agree to it.
            </Typography>
          </Clause>
          <Clause reference="5.1.3">
            <Typography>
              To help us to administer and to contact you about improved administration of any accounts, services and products we have provided before, do provide now or will or may provide in the future.
              This will often be necessary, but sometimes the improvements will not be necessary in which case we will ask whether you agree.
            </Typography>
          </Clause>
          <Clause reference="5.1.4">
            <Typography>
              To allow us to carry out marketing analysis and customer profiling (including with transactional information), conduct research, including creating statistical and testing information.
              This will sometimes require that you consent, but will sometimes be exempt as market research.
            </Typography>
          </Clause>
          <Clause reference="5.1.5">
            <Typography>
              To help to prevent and detect fraud or loss.
              This will only be done in certain circumstances when we consider it necessary or the law requires it.
            </Typography>
          </Clause>
          <Clause reference="5.1.6">
            <Typography>To allow us to contact you by written electronic means (such as email, text or multimedia messages) about products and services offered by us where:</Typography>
          </Clause>
            <Clause reference="5.1.6.1">
              <Typography>these products are similar to those you have already purchased from us,</Typography>
            </Clause>
            <Clause reference="5.1.6.2">
              <Typography>you were given the opportunity to opt out of being contacted by us at the time your personal information was originally collected by us and at the time of our subsequent communications with you, and</Typography>
            </Clause>
            <Clause reference="5.1.6.3">
              <Typography>you have not opted out of us contacting you.</Typography>
            </Clause>
          <Clause reference="5.1.7">
            <Typography>
              To allow us to contact you in any way (including mail, email, telephone, visit, text or multimedia messages) about products and services offered by us and selected partners where you have expressly consented to us doing so.
            </Typography>
          </Clause>
          <Clause reference="5.1.8">
            <Typography>
              To keep you up to date with our account benefit scheme under which, as part of your account benefits, we will give you account information and details of discounts and offers on our products which you may benefit from.
              We will only do this if you have give us your consent for this.
            </Typography>
          </Clause>
          <Clause reference="5.1.9">
            <Typography>
              We may monitor and record communications with you (including phone conversations and emails) for quality assurance and compliance.
            </Typography>
          </Clause>
            <Clause reference="5.1.9.1">
              <Typography>
                Before doing that, we will always tell you of our intentions and of the specific purpose in making the recording.
                Sometimes such recordings will be necessary to comply with the law.
                Alternatively, sometimes the recording will be necessary for our legitimate interest, but in that case we’ll only record the call if our interest outweighs yours.
                This will depend on all the circumstances, in particular the importance of the information and whether we can obtain the information another way that’s less intrusive.
              </Typography>
            </Clause>
            <Clause reference="5.1.9.2">
              <Typography>
                If we think the recording would be useful for us but that it’s not necessary we’ll ask whether you consent to the recording, and will provide an option for you to tell us that you consent.
                In those situations, if you don’t consent, the call will either automatically end or will not be recorded.
              </Typography>
            </Clause>
          <Clause reference="5.1.10">
            <Typography>
              When it’s required by law, we’ll check your details with fraud prevention agencies.
              If you provide false or inaccurate information and we suspect fraud, we intend to record this.
            </Typography>
          </Clause>
        <Clause reference="5.2">
          <Typography>We will not disclose your personal information to any third party except in accordance with this Policy, and in particular in these circumstances:</Typography>
        </Clause>
          <Clause reference="5.2.1">
            <Typography>
              They will be processing the data on our behalf as a data processor (where we’ll be the data controller).
              In that situation, we’ll always have a contract with the data processor as set out in the GDPR.
              This contract provides significant restrictions as to how the data processor operates so that you can be confident your data is protected to the same degree as provided in this Policy.
            </Typography>
          </Clause>
          <Clause reference="5.2.2">
            <Typography>
              Sometimes it might be necessary to share data with another data controller. Before doing that we’ll always tell you.
              Note that if we receive information about you from a third party, then as soon as reasonably practicable afterwards we’ll let you know; that’s required by the GDPR.
            </Typography>
          </Clause>
          <Clause reference="5.2.3">
            <Typography>
              Alternatively, if for any reason in future we consider that it may be in your interest to send your information to a third party we may do this but would always ask whether you agree before sending.
            </Typography>
          </Clause>
        <Clause reference="5.3">
          <Typography>
            Where you give us personal information on behalf of someone else, you confirm that you have provided them with the information set out in this Policy and that they have not objected to such use of their personal information.
          </Typography>
        </Clause>
        <Clause reference="5.4">
          <Typography>In connection with any transaction which we enter into with you:</Typography>
        </Clause>
          <Clause reference="5.4.1">
            <Typography>
              If you provide false or inaccurate information to us and we suspect fraud, we will record this and may share it with other people and organisations.
              We, and other credit and insurance organisations, may also use technology to detect and prevent fraud.
            </Typography>
          </Clause>
          <Clause reference="5.4.2">
            <Typography>
              We may need to transmit the payment and delivery information provided by you during the order process for the purpose of obtaining authorisation from your bank.
              Please note that we do not process card payments ourselves, but this is done through a card processing partner.
            </Typography>
          </Clause>
        <Clause reference="5.5">
          <Typography>We may allow other people and organisations to use personal information we hold about you in the following circumstances:</Typography>
        </Clause>
          <Clause reference="5.5.1">
            <Typography>
              If we, or substantially all of our assets, are acquired or are in the process of being acquired by a third party, in which case personal information held by us, about our customers, will be one of the transferred assets.
            </Typography>
          </Clause>
          <Clause reference="5.5.2">
            <Typography>If we have been legitimately asked to provide information for legal or regulatory purposes or as part of legal proceedings or prospective legal proceedings.</Typography>
          </Clause>
          <Clause reference="5.5.3">
            <Typography>
              We may employ companies and individuals to perform functions on our behalf and we may disclose your personal information to these parties for the purposes set out above, for example, for fulfilling orders, delivering packages, sending postal mail and email, removing repetitive information from customer lists, analysing data, providing marketing assistance, providing search results and links (including paid listings and links) and providing customer service.
              Those parties will be bound by strict contractual provisions with us and will only have access to personal information needed to perform their functions, and they may not use it for any other purpose.
              Further, they must process the personal information in accordance with this Policy and as permitted by the GDPR.
              From time to time, these other people and organisations to whom we may pass your personal information may be outside the European Economic Area.
              We will take all steps reasonably necessary to ensure that your personal information is treated securely and in accordance with this Policy and the GDPR.
            </Typography>
          </Clause>

      <Clause reference="6">
        <Typography>Protecting information</Typography>
      </Clause>
        <Clause reference="6.1">
          <Typography>We have strict security measures to protect personal information.</Typography>
        </Clause>
        <Clause reference="6.2">
          <Typography>We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software to encrypt information you input.</Typography>
        </Clause>
        <Clause reference="6.3">
          <Typography>
            We would only reveal the last five digits of your credit card numbers when confirming an order.
            Of course, the entire credit card number would need to be transmitted to the appropriate credit card company during order processing.
          </Typography>
        </Clause>
        <Clause reference="6.4">
          <Typography>
            We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personally identifiable customer information.
            Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you.
          </Typography>
        </Clause>
        <Clause reference="6.5">
          <Typography>
            It is important for you to protect against unauthorised access to your password and to your computer.
            Be sure to sign off when you finish using a shared computer.
          </Typography>
        </Clause>

      <Clause reference="7">
        <Typography>The internet</Typography>
      </Clause>
      <Clause reference="7.1">
        <Typography>
          If you communicate with us using the internet, we may occasionally email you about our services and products.
          When you first give us personal information through the Website, we will normally give you the opportunity to say whether you would prefer that we don’t contact you by email.
          You can also always send us an email (at the address set out below) at any time if you change your mind.
        </Typography>
      </Clause>
      <Clause reference="7.2">
        <Typography>
          Please remember that communications over the internet, such as emails and webmails (messages sent through a website), are not secure unless they have been encrypted.
          Your communications may go through a number of countries before they are delivered – this is the nature of the internet.
          We cannot accept responsibility for any unauthorised access or loss of personal information that is beyond our control.
        </Typography>
      </Clause>
      <Clause reference="7.3">
        <Typography>
          The Website may include links to third-party websites.
          We do not provide any personally identifiable customer personal information to these third-party websites unless you’ve consented in accordance with this privacy policy.
        </Typography>
      </Clause>
      <Clause reference="7.4">
        <Typography>
          We exclude all liability for loss that you may incur when using these third-party websites.
        </Typography>
      </Clause>

      <Clause reference="8">
        <Typography>Further information</Typography>
      </Clause>
      <Clause reference="8.1">
        <Typography>If you would like any more information or you have any comments about this Policy, please either write to us at Data Protection Manager, {COMPANY_NAME}, {COMPANY_ADDRESS}, or email us at {PRIVACY_EMAIL}.</Typography>
      </Clause>
      <Clause reference="8.2">
        <Typography>
          Please note that we may have to amend this Policy on occasion, for example if we change the cookies that we use.
          If we do that, we will publish the amended version on the Website.
          In that situation we will endeavour to alert you to the change, but it’s also your responsibility to check regularly to determine whether this Policy has changed.
        </Typography>
      </Clause>
      <Clause reference="8.3">
        <Typography>
          You can ask us for a copy of this Policy by writing to the above address or by emailing us at {PRIVACY_EMAIL}.
          This Policy applies to personal information we hold about individuals.
          It does not apply to information we hold about companies and other organisations.
        </Typography>
      </Clause>
      <Clause reference="8.4">
        <Typography>
          If you would like access to the personal information that we hold about you, you can do this by emailing us at {PRIVACY_EMAIL} or writing to us at the address noted above. There is not normally a fee for such a request, however if the request is unfounded, repetitive or excessive we may request a fee or refuse to comply with your request.
          You can also ask us to send the personal information we hold about you to another controller.
        </Typography>
      </Clause>
      <Clause reference="8.5">
        <Typography>
          We aim to keep the personal information we hold about you accurate and up to date.
          If you tell us that we’re holding any inaccurate or incomplete personal information about you, we will promptly amend, complete or delete it accordingly. Please email us at {PRIVACY_EMAIL} or write to us at the address above to update your personal information.
          You have the right to complain to the Information Commissioner’s Office if we don’t do this.
        </Typography>
      </Clause>
      <Clause reference="8.6">
        <Typography>
          You can ask us to delete the personal information that we hold about you if we relied on your consent in holding that information or if it’s no longer necessary.
          You can also restrict or object to our processing of your personal information in certain circumstances.
          You can do this by emailing us at {PRIVACY_EMAIL} or writing to us at the address noted above.
        </Typography>
      </Clause>
      <Clause reference="8.7">
        <Typography>
          We will tell you if there is a breach, or a likely breach, of your data protection rights.
        </Typography>
      </Clause>

    </Grid>
  )
}

export default PrivacyPolicy;