import React from 'react';
import { useParams } from "react-router";
import { Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import { solutions } from "../data/data";
import PageBreadcrumbs from "../components/PageBreadcrumbs";

const Solution = props => {
  let { id } = useParams();

  let solution = solutions[id];

  if (solution === undefined) {
    return (
      <p>Sorry cannot find solution</p>
    )
  }

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <PageBreadcrumbs
          categoryID="solutions"
          categoryTitle="Solutions"
          pageTitle={solution.title}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardMedia
            component="img"
            sx={{ height: { xs: 200, md: 300} }}
            image={solution.image}
            alt="Explore urban design ideas"
          />
          <CardContent>
            <Typography
              variant="h3"
              sx={{ pt: 1, pb: 1 }}
            >
              {solution.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              {solution.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {solution.longDescription}
    </Grid>
  )
}

export default Solution;