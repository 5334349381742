import React from 'react';
import {Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";

const GenericCard = props => {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card>
        <CardMedia
          component="img"
          height="200"
          image={props.image}
          alt=""
        />
        <CardContent>
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', pt: 1 }}
          >
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{  textAlign: 'center', pt: 1 }}
          >
            {props.description}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default GenericCard;