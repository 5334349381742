import React from 'react';
import {Box, Button, Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import {colours} from "../config";

const CityCAD = props => {
  return (
    <Grid container spacing={2}
          maxWidth="lg"
          sx={{
            justifyContent: 'center',
            p: 2
          }}
    >
      <Grid item xs={12}>
        <Card>
          <CardMedia
            component="img"
            height="525"
            image="/images/sketch-explore.png"
            alt="Panoramic view of city"
          />
          <CardContent>
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', pt: { xs: 1, md: 4} }}
            >
              Introducing CityCAD
            </Typography>

            <Typography
              variant="h5"
              sx={{ textAlign: 'center', color: colours.bluePrimary, pt: { xs: 1, md: 4} }}
            >
              CityCAD is a proven city information modelling solution for quickly exploring, testing and communicating urban masterplans.
            </Typography>

          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h5"
          component="h2"
          sx={{my: 3, textAlign: 'center', fontWeight: 400, textTransform: 'uppercase', color: colours.darkGrey}}
        >
          How does CityCAD work?
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardMedia
            component="img"
            height="200"
            image="/images/explore.png"
            alt="Explore urban design ideas"
          />
          <CardContent>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', pt: { xs: 1, md: 1} }}
            >
              1
            </Typography>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', pt: { xs: 1, md: 1} }}
            >
              Explore urban design concepts for your site
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardMedia
            component="img"
            height="200"
            image="/images/test.png"
            alt="Test urban design ideas"
          />
          <CardContent>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', pt: { xs: 1, md: 1} }}
            >
              2
            </Typography>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', pt: { xs: 1, md: 1} }}
            >
              Test and validate many design approaches
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardMedia
            component="img"
            height="200"
            image="/images/communicate.jpg"
            alt="Test urban design ideas"
          />
          <CardContent>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', pt: { xs: 1, md: 1} }}
            >
              3
            </Typography>
            <Typography
              variant="h6"
              sx={{ textAlign: 'center', pt: { xs: 1, md: 1} }}
            >
              Engage with stakeholders and discuss your ideas
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default CityCAD;