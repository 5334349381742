import React from 'react';
import {Link} from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

const PageBreadcrumbs = props => {
  return (
    <Breadcrumbs arial-label="breadcrumb" sx={{ mt: 2 }}>
      <Link underline="hover" color="inherit" to="/">
        Home
      </Link>
      {
        props.pageTitle === undefined
        &&
        <Typography color="text.primary">{props.categoryTitle}</Typography>
      }
      {
        props.pageTitle !== undefined
        &&
        <Link underline="hover" color="inherit" to={`/${props.categoryID}`}>
          {props.categoryTitle}
        </Link>
      }
      {
        props.pageTitle !== undefined
        &&
        <Typography color="text.primary">{props.pageTitle}</Typography>
      }
    </Breadcrumbs>
  )
}

export default PageBreadcrumbs;