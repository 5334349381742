/*
 * Converts an object into an array and adds an 'id' from the object key
 */
const convertObjectToArray = (obj) => {
  let arr = [];
  for (const id in obj) {
    obj[id].id = id;
    arr.push(obj[id])
  }
  return arr;
}

export default convertObjectToArray;