import React from "react";
import {Box, Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import { colours } from "../config";
import CategoryHeader from "../components/CategoryHeader";
import GenericCard from "../components/GenericCard";

export const assetSimLongDescription = (
  <React.Fragment>

    <CategoryHeader>What is AssetSIM?</CategoryHeader>

    <Grid item xs={12} md={6}>
      <Box sx={{display:'flex', justifyContent: 'center'}}>
        <Box
          sx={{ width: '100%', maxWidth: '400px' }}
          component="img"
          src="/images/screenshot-generic.png"
        />
      </Box>
    </Grid>

    <Grid item xs={12} md={6}>
      <Box sx={{ width: '100%', maxWidth: '500px' }}>
        <Typography variant="body1" paragraph>
          AssetSIM is an advanced real estate portfolio management tool built on the CityCAD platform.
        </Typography>
        <Typography variant="body1" paragraph>
          AssetSIM combines an intuitive 3D visualization with sophisticated data management and analysis features in a single application.
          Test different development, fit-out and refurbishment scenarios against a range of criteria.
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontWeight: 'bold'}}>
          AssetSIM makes it easy to visualize changes over time.
          Move a time slider through your scenario and watch as buildings appear and change.
        </Typography>
      </Box>
    </Grid>

    <CategoryHeader>Why use AssetSIM?</CategoryHeader>

    <Grid item xs={12} md={6}>
      <Box sx={{display:'flex', justifyContent: 'center'}}>
        <Box
          sx={{ width: '100%', maxWidth: '400px' }}
          component="img"
          src="/images/paper.jpg"
        />
      </Box>
    </Grid>

    <Grid item xs={12} md={6}>
      <Box sx={{ width: '100%', maxWidth: '500px' }}>
        <Typography variant="body1" paragraph>
          Conventional real estate development plans capture an intended scenario at a fixed point in time.
        </Typography>
        <Typography variant="body1" paragraph>
          These plans contain valuable knowledge and analysis but they will slowly go out of date over time.
        </Typography>
        <Typography variant="body1" paragraph sx={{ fontWeight: 'bold'}}>
          With AssetSIM, you can build a smart real-time 3D model of your development programme.
        </Typography>
        <Typography variant="body1" paragraph>
          When things change, you can adjust cost, phasing or design details in your smart model and immediately see the results.
        </Typography>
      </Box>
    </Grid>

    <CategoryHeader>What are the benefits of AssetSIM?</CategoryHeader>

    <GenericCard
      image="/images/engine.jpg"
      title="Efficiency savings"
      description="No need to commission expensive replacement studies or updates - simply adjust parameters in your existing model."
    />

    <GenericCard
      image="/images/shibuya.jpg"
      title="Quality Improvements"
      description="Explore and test a large number of scenarios, allowing you to optimize your plan further than was possible before."
    />

    <GenericCard
      image="/images/assets.jpg"
      title="True 5D Analysis"
      description="AssetSIM offers an information-rich model of not just the 3D building arrangement but also time and cost."
    />

    <GenericCard
      image="/images/optimize.jpg"
      title="Advanced Optimization"
      description="Fine tune the operational and financial performance of your development options before making decisions."
    />

    <GenericCard
      image="/images/demandsupply.png"
      title="The right space at the right time"
      description="Visualize the expected demand for specific space uses against the projected supply at different points in time."
    />

    <GenericCard
      image="/images/board-meeting.jpeg"
      title="Enhanced communication"
      description="Generate summary reports and step-by-step 3D animations to explain your development programme at board level."
    />

    <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
      <Card>
        <CardContent>
          <Typography
            variant="body1"
            sx={{ textAlign: 'center' }}
          >
            If you would like to use AssetSIM to manage your development scenarios, contact sales@holisticcity.co.uk to arrange a call.
          </Typography>
        </CardContent>
      </Card>
    </Grid>

  </React.Fragment>
)