import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {Link} from "react-router-dom";
import convertObjectToArray from "../utilities/convertObjectToArray";
import {mapItems} from "../data/data";

const ProjectMap = () => {

  const mapItemArray = convertObjectToArray(mapItems);

  return (
    <Grid item xs={12}>
      <Box>
        <MapContainer style={{height: '600px'}} center={[35.505, -0.09]} zoom={2} scrollWheelZoom={false}>
          <TileLayer
            attribution={`&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`}
            url={`https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png?key=FnXdscnHosMzQSGkH9C7`}
          />
          {
            mapItemArray.map(project => {
              return (
                <Marker key={project.id} position={project.location}>
                  <Popup>
                    <Typography variant="h6">{project.title}</Typography>
                    {
                      project.image !== undefined
                      &&
                      <Box sx={{ width: '100%'}} component="img" src={project.image} />
                    }
                    <Typography variant="p">{project.description}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                      <Link to={`/map/${project.id}`}>Read more</Link>
                    </Box>
                  </Popup>
                </Marker>
              )
            })
          }

        </MapContainer>
      </Box>
    </Grid>
  )
}

export default ProjectMap;