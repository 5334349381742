import React from 'react';
import { useParams } from "react-router";
import { Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import { getNewsItem } from "../data/data";
import PageBreadcrumbs from "../components/PageBreadcrumbs";

const NewsArticle = props => {
  let { id } = useParams();

  let newsItem = getNewsItem(id);

  if (newsItem === undefined) {
    return (
      <p>Sorry cannot find news article</p>
    )
  }

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <PageBreadcrumbs
          categoryID="news"
          categoryTitle="News"
          pageTitle={newsItem.title}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardMedia
            component="img"
            sx={{ height: { xs: 200, md: 300} }}
            image={newsItem.image}
            alt="Explore urban design ideas"
          />
          <CardContent>
            <Typography
              variant="h3"
              sx={{ pt: 1, pb: 1 }}
            >
              {newsItem.title}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {newsItem.content}
      </Grid>
    </Grid>
  )
}

export default NewsArticle;