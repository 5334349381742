import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export const newsItems = [
  {
    id: 1,
    date: "2006-08-07",
    title: "Stay in touch",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City has big plans for 2006 and 2007 - if you'd like to be kept up-to-date, then please sign up for occasional news updates at the top of the page.
        </p>
        <p>
          We will soon be releasing details of some computer-related urban design events and discussions we are planning to host in September this year. In the meantime, we hope you enjoy your visit to the site.
        </p>
      </React.Fragment>
    )
  }, // Stay in touch
  {
    id: 2,
    date: "2006-08-07",
    title: "Download Streetscape 1.1 (FREE)",
    image: "/images/streetscape_intro_3.png",
    content: (
      <React.Fragment>
        <p>
          We created Streetscape to make life easier for urban designers, highways engineers and others involved in the design of the built environment.
        </p>
        <p>
          It is a simple, quick, software tool which allows different street designs to be easily created and tested, and on-street parking spaces to be easily calculated.. Click here to read more,
        </p>
        <p>
          Click here to give it a try, completely free of charge - and please contact us to let us know what you think. We value your comments!
        </p>
      </React.Fragment>
    )
  }, // Streetscape 1.1
  {
    id: 3,
    date: "2008-06-02",
    title: "Holistic City Software introduces CityCAD 1.0",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Ltd, a new urban design software company, will release CityCAD 1.0 on 9 June 2008.
          CityCAD is a new software tool for designing and analysing large scale masterplans such as Eco Towns.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 1.0 Preview
  {
    id: 5,
    date: "2008-08-12",
    title: "CityCAD 1.1 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Ltd has released the first upgrade to CityCAD, its new CIM (City Information Modelling) package.
          Version 1.1 is now available for download with a flexible 8-hour free trial.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 1.1
  {
    id: 6,
    date: "2008-10-29",
    title: "CityCAD 1.5 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 1.5 - a significant upgrade to its new CIM (City Information Modelling) package.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 1.5
  {
    id: 7,
    date: "2008-12-01",
    title: "Holistic City Software provides conceptual planning technology for South American Eco City",
    image: "/images/puerto-paez.jpg",
    content: (
      <React.Fragment>
        <p>
          CityCAD, a new urban modelling technology developed by Holistic City, has been used to produce a preliminary conceptual masterplan of the new Puerto Paez Eco City.
        </p>
        <p>
          Puerto Paez is the first phase of a 1 million hectare development programme along the Orinoco and Meta rivers in the centre of Venezuela.
        </p>
      </React.Fragment>
    )
  }, // Puerto Paez Eco City
  {
    id: 7,
    date: "2008-12-01",
    title: "CityCAD Education Licence Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          We're pleased to announce the release of a long-awaited educational licence for CityCAD.
          This is available as a 12-month subscription, at a special introductory price of only £48.30 (inc. VAT) per user.
        </p>
      </React.Fragment>
    )
  }, // Education licence released
  {
    id: 8,
    date: "2009-01-22",
    title: "Urban Design Analysis Made Simple in CityCAD 1.6",
    image: "images/citycad_onepointsix.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 1.6, the latest version of the urban design software tool, which includes new features and graphics capabilities making it easier and quicker than ever before to create and study urban masterplan options.
        </p>
        <p>
          <img src={`${process.env.PUBLIC_URL}/images/citycad_onepointsix.png`} alt="CityCAD 1.6 screenshot"/>
        </p>
        <h3>Illustrate your Model with Graphics</h3>
        <p>
          You can now quickly add a range of urban design graphics to your 3D models including circular placemarks, arrows and 'landmark' stars. Express important features in your masterplan such as key buildings, areas for improvement or movement routes and views.
        </p>
        <p>
          You can now also draw Isovists - click at your selected viewpoint in the model and the approximate field of view will be displayed. You can use this to test what can be seen from where, and get a better sense of the legibility of your masterplan.
        </p>
        <h3>New Features</h3>
        <p>
          CityCAD 1.6 also features several minor bug fixes, performance enhancements and usability improvements.
          There is now an Autosave, a new filter on the select tool (allowing you to select only specific parts of a model), a zoom extents and a zoom window tool.
          See full list of new features.
        </p>
        <h3>Save Time while Protecting Design Quality </h3>
        <p>
          CityCAD 1.6 has kept all of the core CIM (City Information Modelling) features which help to analyse and understand complex urban design models very quickly. A large number of masterplanning scenarios can be created and tested in little time, reducing project costs and making it possible to evolve and refine a design much further than was possible before.
        </p>
        <h3>CityCAD 1.6 - Full List of Improvements / New Features</h3>
        <ul>
          <li>New Graphics Object Tool for creating arrows, landmarks, placemarks and isovists.</li>
          <li>Graphics Object Manager for deleting, selecting graphics objects.</li>
          <li>Automatic Backup Save - intervals and number of backup files can be set manually.</li>
          <li>Zoom Window</li>
          <li>Zoom Extents</li>
          <li>Filter for Select Tool - can select only specific kinds of elements</li>
          <li>Additional currencies added to custom unit system at user request</li>
          <li>Several minor usability improvements and performance enhancements</li>
        </ul>
        <h3>(Full List of Improvements / New Features since CityCAD 1.1)</h3>
        <ul>
          <li>Custom Land Use Library</li>
          <li>Saved View Library</li>
          <li>3D DXF CAD model library - can be assigned to City Items</li>
          <li>Enhanced City Item Properties - can specify an area and add Land Use Subdivisions (eg. as per Mixed-Use Blocks)</li>
          <li>Building Sculpting - if you select 'None' land use in a Building Floor or Subdivision, it will disappear from the model and its floor area will no longer be calculated in the City Grid. You can use this to 'sculpt' more complex building and block forms.</li>
          <li>Apply City Defaults to several elements at a time (eg. you can select all elements in a neighbourhood group and apply settings)</li>
          <li>New 'Move To...' feature - you can move elements to exact coordinates or by exact vectors.</li>
          <li>Additional outputs in City Grid - Approximate Building Envelope, Building Volume and Approximate Surface-Volume ratio</li>
          <li>Redesigned DXF Import Window - base maps, city items and DXF-URB now organized in separate tabs.</li>
          <li>When importing DXF lines and converting them into CityCAD Routes, you can now specify route and block type from within the DXF Import window.</li>
          <li>Redesigned Land Use Preferences Dialog.</li>
          <li>Miscellaneous minor bug fixes and performance optimizations</li>
          <li>Export to KML format</li>
        </ul>
      </React.Fragment>
    )
  }, // CityCAD 1.6
  {
    id: 9,
    date: "2009-02-25",
    title: "CityCAD 1.6.2 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 1.6.2, which includes several minor usability and performance improvements based on feedback received from the growing CityCAD user community.
        </p>
        <h3>Labels</h3>
        <p>Image: stories/citycad/citycad_labels.png</p>
        <p>
          You can now superimpose labels which update if you change the name of a block.
          These make it easier to keep track of of your city model, and to explain your city model to others.
        </p>
        <h3>Easier Import of Information into CityCAD</h3>
        <p>
          You can now import several DXF base maps at the same time, change the colour of each one, and switch then on or off as required.
        </p>
        <p>
          Also, if you import images to place on the ground, and then trace over them with CityCAD routes, a couple of new features will make this easier.
          When adding images using 3 point positioning, new controls make it easier to place images more accurately.
        </p>
        <p>
          Once dxf base maps or images are in position, new 'tracing' settings in the snap preferences makes it easier to draw over the image.
        </p>
        <h3>New Online Resources in the CityCAD 'General' Tab</h3>
        <p>
          Do you ever need to quickly convert scale factors, for example copy a drawing from 1:1250 to 1:500?
        </p>
        <p>
          A revamped information service is now available in the General tab - here you can click through to several technical resources for converting scales, or different area units, or refer to standard paper sizes.
          Links are also available to several major news and information websites.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 1.6.2
  {
    id: 10,
    date: "2009-04-14",
    title: "CityCAD 1.6.4 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 1.6.4, the latest version of the urban design software tool.
          This is a maintenance release, meaning there aren't any significant new features, but our development team has made many improvements, bug-fixes and performance enhancements behind the scenes.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 1.6.4
  {
    id: 11,
    date: "2009-07-09",
    title: "CityCAD in Local Government",
    image: "/images/towerhamlets.png",
    content: (
      <React.Fragment>
        <p>
          In the last year, our new city design and planning tool - CityCAD - has been used on several masterplanning projects around the world.
          This is a summary of three very different case studies - a small regeneration project in the US, various masterplanning projects in East London, and a new eco city in South America.
        </p>
      </React.Fragment>
    )
  }, // CityCAD in local government
  {
    id: 12,
    date: "2009-07-09",
    title: "CityCAD is one year old!",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Today, CityCAD celebrates its first birthday.
          We'd like to extend a huge thanks to all of our customers, newsletter subscribers, beta testers and others.
          CityCAD would not have been possible without your continued interest and support.
        </p>
      </React.Fragment>
    )
  }, // 1 year anniversary of launch
  {
    id: 13,
    date: "2009-07-17",
    title: "CityCAD 1.7 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 1.7.
          This includes significant new features such as a new route and tree type manager, improved visualization and a new 'Export DXF Outlines' export feature.
          It also includes several other minor improvements and usability enhancements.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 1.7
  {
    id: 14,
    date: "2009-08-26",
    title: "CityCAD 1.8 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 1.8.
          This includes various improvements including a new sun-study tool and a 'tree spray' tool for quickly enhancing visualizations with trees.
          This will be the final release before CityCAD 2.0, due later this autumn.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 1.8
  {
    id: 15,
    date: "2010-07-30",
    title: "CityCAD 2.0 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software is pleased to announce the release of CityCAD 2.0 - a major new upgrade to their conceptual urban masterplanning CAD application.
        </p>
        <p>
          Image: Solutions/pressinfo/citycad2_drawing_small.png
        </p>
        <p>
          CityCAD was created for city masterplanners who are frustrated by constantly recalculating large quantities of data every time a masterplan design is changed during the design process.
        </p>
        <p>
          It is an easy-to-use CAD application for conceptual urban masterplanning. Unlike other CAD software products, CityCAD was specifically created for the city design community and will automatically recalculate planning data (including floor areas, residential densities, costs, values, energy/carbon use) in real time as you sketch and modify your design.
        </p>
        <p>
          Even if your team hasn't used much CAD software before, CityCAD will enable you to quickly sketch 3D models of large scale urban masterplans and easily test many different development ideas.
          You can also import your own information, such as existing masterplan drawings, maps, aerial photos or concept sketches.
        </p>
        <p>
          Holistic City has assembled an all-inclusive subscription offer which includes technical support and all upgrades/new versions for the duration of your subscription. For a limited time until July 14th, a full commercial 6-month subscription package is available for only GBP 250 / USD 350.
          Significant discounts are available on non-commercial licences for educational use.
        </p>
        <p>
          For more details please visit the online store
        </p>
        <h3>Rapid Masterplan Sketching</h3>
        <p>
          CityCAD has always been a very quick way to sketch urban masterplans in three dimensions, and the latest release will enable you to explore concepts and create high quality design faster than ever before.
        </p>
        <p>
          This exciting new release includes parametric visualization for detached housing blocks - enter a density and watch as the houses get closer together or further apart.
        </p>
        <p>
          Other features include a new City Design Assistant panel where you can access online help, do batch editing of offers rapid creation of blocks of a certain size, as well as customized urban gridiron layouts.
        </p>
        <p>
          New library of batch editing features - for example change the no. of storeys of several blocks at the same time.
        </p>
        <p>
          City defaults have been replaced with easy-to-use Block, Route, City Item, Tree and Land Use Managers, where you can create and manage your own custom libraries of settings.
          Settings can be easily 'painted' on to elements in your masterplan using the new Apply Settings tool.
        </p>
        <h3>Advanced City Analysis</h3>
        <p>
          Image: pressinfo/citycad2_aerial_perspective_small.png
        </p>
        <p>
          City analysis - and the ability to quickly relate spatial information to numerical information - is a key part of the value that urban design software can bring to your work.
        </p>
        <p>
          In CityCAD 2.0, a powerful new Advisor feature allows you to set detailed criteria and thresholds, and be alerted if they are crossed.
          You can also define and apply custom parameters - including customizable environmental standards and ownership types.
        </p>
        <p>
          New advanced liveability analysis provides real-time feedback on living space, access to services (average and maximum distance to places eg. schools, bus stops, post boxes), active frontage and diversity. Advanced sustainability analysis displays the total units or GFA according to environmental standard, and can detect the orientation of rectangular blocks.
        </p>
        <h3>Background</h3>
        <p>
          CityCAD began as a research project that applied BIM (Building Information Modelling) principles to the urban masterplan scale.
          A prototype software product codenamed 'Masterplanner' was created in early 2005.
        </p>
        <p>
          Holistic City released a small street design tool later in 2005 called Streetscape, which was later upgraded to Streetscape Pro in 2007.
          Both are available for download free of charge.
        </p>
        <p>
          CityCAD 1.0 was launched in June 2008, and an ongoing programme of development and improvement based on customer feedback has culminated in the release of CityCAD 2.0 in June 2010. We continue to explore ways that CityCAD can assist the creation of sustainable, liveable cities, and are always grateful for feedback on how our software could help your work.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.0
  {
    id: 16,
    date: "2010-09-08",
    title: "CityCAD 2.0.5 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 2.0.5, the latest version of the urban design software tool.
          This is a maintenance release, meaning there aren't any significant new features, but our development team have made several improvements and fixes.
        </p>
        <h3>Revised City Design Reference Library</h3>
        <p>
          The Online Database is now linked to the new City Design Reference Library, and allows you to access relevant case studies of urban streets, urban spaces and housing projects.
        </p>
        <p>
          For example, if you sketched an urban space in CityCAD that was about 0.4ha in size, the online database could provide you with a list of real-world urban spaces of about the same size.
        </p>
        <p>
          Image: stories/citycad/onlinedatabase_small.png
        </p>
        <h3>Add DXF Base Maps to 2D drawing</h3>
        <p>
          A minor improvement is that if you have imported any DXF Base Maps, you can now display these in 2D plan diagrams displayed in the 2D window.
        </p>
        <h3>Fixes</h3>
        <p>
          Our development team has also made several minor fixes and improvements.
          For example, some users also reported occasional faults with shadows not appearing in exported images, and this issue has now been resolved.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.0.5
  {
    id: 17,
    date: "2010-09-09",
    title: "Free CityCAD Viewer Released",
    image: "/images/citycad_viewer.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released 'CityCAD Viewer' - a new free application for opening and exploring URB-format city models created with CityCAD.
        </p>
      </React.Fragment>
    )
  }, // CityCAD Viewer
  {
    id: 18,
    date: "2010-09-28",
    title: "CityCAD featured in AEC Magazine",
    image: "/images/aecmag_cover.png",
    content: (
      <React.Fragment>
        <p>
          CityCAD has been reviewed by Martyn Day in the September/October issue of AEC Magazine.
        </p>
      </React.Fragment>
    )
  }, // CityCAD review in AEC magazine
  {
    id: 19,
    date: "2010-12-16",
    title: "CityCAD 2.0.6 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 2.0.6, the latest version of the urban design software tool.
          This is a maintenance release, meaning there aren't any significant new features, but our development team have made several minor improvements and fixes.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.0.6
  {
    id: 20,
    date: "2011-03-14",
    title: "CityCAD 2.1 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 2.1, a significant upgrade of the city design CAD application.
          This version contains several major improvements in performance and stability, as well as some exciting new features such as shading according to distance from certain land uses.
        </p>
        <p>
          In CityCAD 2.1, blocks and city items can be shaded according to distance from a specific land use, or type of object such as a bus stop.
          They can also be shaded according to whether they comply with specified distance thresholds, for example whether a block is within 800m of a rail station.
          This means the designer can instantly identify problem areas and quickly gain a greater understanding of the overall liveability of a masterplan.
        </p>
        <p>
          Other improvements include an extension to the custom parameters - these can now be applied to Routes and Nodes, as well as Blocks and Subdivisions.
          Elements in your model can also be shaded according to custom parameters, making it easier to keep track of customized categories or values in your city model.
        </p>
        <p>
          CityCAD 2.1 comes with a 30-day free trial, and can be downloaded from http://www.holisticcity.co.uk/downloads.
          If your free trial has expired from using an earlier version, contact support@holisticcity.co.uk and a new trial licence will be sent.
        </p>
        <h3>New City Analysis Shading</h3>
        <p>
          In the image below, the blocks are shaded according to distance from a bus stop, making it easy to see which blocks are more accessible.
        </p>
        <p>
          Image: pressinfo/citycad_shadingbydistance_city.png<br />

          Buildings shaded according to distance from bus stop
        </p>
        <h3>Summary of all new features introduced since June 2010</h3>
        <h4>Drawing / Editing Features</h4>
        <ul>
          <li>Improved DXF CAD Import - DXF Base Maps ('xrefs') can now be easily refreshed from source file.</li>
          <li>You can import a DXF CAD file and automatically convert into a number of City Items, placed at the correct location.</li>
          <li>Ortho Snap drawing constraints.</li>
          <li>New keyboard shortcuts - Ctrl-W (Zoom to Window), Ctrl-E (Zoom to Extents), Ctrl-I (Image-tracing snap settings) and Ctrl-D (DXF-tracing snap settings).</li>
          <li>New 'Reverse Direction' control when editing Streetscape Elements of a route.</li>
          <li>Analysis and Information Features</li>
          <li>New Liveability Shading features - shade blocks according to distance from services, land uses or city item types. Shade according to absolute distance, or based on Liveability thresholds.</li>
          <li>Custom Parameters extended to routes.</li>
          <li>Shading according to custom parameters.</li>
          <li>City Design Assistant Panel</li>
          <li>Revised Embedded Browser - relevant help articles and resources displayed according to user preferences.</li>
          <li>Online Database - link from CityCAD to improved online database of urban spaces and housing projects</li>
          <li>Toolbars</li>
          <li>Switch boundary line visibility between 'always invisible', 'always visible' or 'show if not hidden by blocks'</li>
          <li>Snaps Toolbar - select dxf or image tracing, and quickly toggle snaps on and off when drawing routes</li>
        </ul>
        <h4>Miscellaneous</h4>
        <ul>
          <li>Higher performance graphics rendering and reduced memory use for blocks and trees. Improved render structures for larger numbers of routes.</li>
          <li>New alert when deleting custom parameters (with 'don't show this again' option)</li>
          <li>If free trial fails to activate, link to email client program provided to request licence code for trial.</li>
          <li>Many new cities added to internal database in Sun/Sky settings.</li>
          <li>Z-depth bias added to avoid visualization glitches when viewing very large scale blocks set at ground plane level.</li>
          <li>New DXF import dialog will suggest level of detail adjustments if a large file is being imported using the 'DXF-URB' method.</li>
          <li>Miscellaneous fixes and minor improvements.</li>
        </ul>
      </React.Fragment>

    )
  }, // CityCAD 2.1
  {
    id: 21,
    date: "2011-05-26",
    title: "New CityCAD Licence Offers",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Holistic City Software has released CityCAD 2.0.6, the latest version of the urban design software tool.
          This is a maintenance release, meaning there aren't any significant new features, but our development team have made several minor improvements and fixes.
          Holistic City Software (HCL) has released three new special offers to mark three years since the launch of CityCAD 1.0:
        </p>
        <ul>
          <li>A discounted 'studio' monthly subscription for small organizations (1-10 people)</li>
          <li>A discounted perpetual (no expiry) licence for small organizations (1-10 people)</li>
          <li>A free 'campus' licence for educational institutions. </li>
        </ul>
        <h3>Your feedback</h3>
        <p>
          HC recently conducted an online survey, and has begun compiling the results in order to inform software development over the next few months.
          More information about future versions will be published when it is ready.
          There was one clear demand that emerged from the survey - many people wanted a discounted version of CityCAD for smaller organizations.
        </p>
        <h3>Studio version</h3>
        <p>
          Today, HCL are pleased to announce the 'Studio Version', where you can sign up to a commercial subscription of CityCAD for a fraction of the normal subscription rates.
          A discounted perpetual licence is also available.
          For full details of pricing in both GBP and USD please visit the online store.
        </p>
        <h3>Campus Version</h3>
        <p>
          At the same time free 'campus' licences are being introduced for academic staff and students involved with recognized courses of urban planning, architecture, surveying, engineering, sustainability and related subjects.
        </p>
        <p>
          Contact HCL at sales@holisticcity.co.uk with details of your course and a licence number can then be generated for activating several PCs until June 2013.
          Please note that subject to demand it may not be possible to always provide technical support for free campus licences.
        </p>
        <h3>New version</h3>
        <p>
          At the same time a minor upgrade - CityCAD 2.1.2 - has been released on the website.
          This does not contain any new features, but resolves a couple of obscure problems that had been detected since the last release.
          HCL requests that this upgrade is downloaded and installed at the next convenient opportunity.
        </p>
        <p>
          HCL is always interested to hear about your projects and so please get in touch if you have any comments or would like to share any experiences of CityCAD - both good or bad.`
        </p>
      </React.Fragment>
    )
  }, // New CityCAD Licence offers
  {
    id: 22,
    date: "2011-09-23",
    title: "CityCAD 2.2 released with new data import engine",
    image: "/images/000_04_dataimport.png",
    content: (
      // https://web.archive.org/web/20120322105711im_/http://www.holisticcity.co.uk/images/stories/Solutions/pressinfo/dataimportverysmall.png
      <React.Fragment>
        <p>Holistic City Software has released CityCAD 2.2 with a new data import engine and improved 2d drawings, as well as several new features and further improvements in stability and performance. </p>
        <h3>CityCAD 2.2 - new data import feature</h3>
        <img src={`/images/000_04_dataimport.png`} />
        <h3>Import data directly into your city model</h3>
        <p>
          The new data import feature is a key step in our longer term programme to extend interoperability with other software tools.
        </p>
        <p>
          You can now import a CSV file or Excel spreadsheet, and automatically create custom parameters for elements in your model from the data.
          You can update existing parameters or create new ones as part of the import process.
        </p>
        <p>
          The data import feature can also directly edit a number of CityCAD properties - for example if you have a spreadsheet with block names and number of storeys, this can be used to set the heights of the buildings in your CityCAD model. Densities, unit sizes, energy use and other parameters can also be updated automatically.
        </p>
        <h3>2D plan diagrams</h3>
        <p>
          We have improved the 2D Plan Diagram window, and you can now shade blocks, city items and routes separately.
          The new distance, liveability and custom parameter shading, which is already available in the 3D view, has been extended to the 2D view.
        </p>
        <p>
          Perimeter objects, such as house models on semi-detached/detached housing blocks, can now be shaded separately to provide extra analysis and more descriptive figure-ground diagrams.
        </p>
        <p>
          Image: https://web.archive.org/web/20120322105716im_/http://www.holisticcity.co.uk/images/stories/Solutions/pressinfo/citycad_figureground_perimeterobjects.png
        </p>
        <h3>Specify unit density when editing land use subdivisions</h3>
        <p>
          When editing land use subdivisions in mixed-use blocks or city items, you can now specify a 'unit density' for each one, and this will automatically set the number of units
        </p>
        <h3>Miscellaneous other minor improvements</h3>
        <ul>
          <li>The data export system has been improved 'under the hood' so you can now export your data to XLS format without needing to have Excel installed on your PC.</li>
          <li>Shading has been improved, and we have introduced a new 'High Rise' option when shading by number of storeys, to improve legibility when there are many tall buildings.</li>
          <li>New 'Purge Unused' button makes it easy to clear the File DXF Library of DXF City Items that are not used in your current CityCAD model.</li>
          <li>New keyboard shortcuts to set snaps to default or narrow range.</li>
        </ul>
        <p>
          As with all of our new releases, we have fixed known bugs and improved the speed and performance of CityCAD wherever possible.
          We have a policy of fixing reproduceable bugs as soon as possible after they are detected, and so please contact us at support@holisticcity.co.uk if you experience any problems.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.2 / Data Import
  {
    id: 23,
    date: "2011-10-20",
    title: "Updated Free CityCAD Viewer released",
    image: "/images/citycad_viewer_screenshot_small.png",
    content: ""
  }, // Updated Free CityCAD Viewer
  {
    id: 24,
    date: "2012-08-23",
    title: "CityCAD 2.2.4 now available",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>Thank you for your continued interest in our work. We'd like to let you know that CityCAD 2.2.4 is now available for download on our website.</p>
        <p>
          This is a maintenance release with some minor improvements.
          The graphics performance of ground plane images has been enhanced, and more options have been added for managing custom land uses, groups and phases when importing one CityCAD (URB) model into URB file.
        </p>
        <p>
          The online database of urban streets, spaces and housing projects has been revised with more information, and later this year we aim to introduce a feature where you can suggest new entries and add your own ratings and comments to each one.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.2.4
  {
    id: 25,
    date: "2012-11-29",
    title: "CityCAD 2.4 Released",
    image: "/images/000_05_customshading.png",
    content: (
      <React.Fragment>
        <p>
          We are pleased to announce the release of CityCAD 2.4 which introduces a number of new city modelling and analysis features.
        </p>
        <ol>
          <li>A new 'adjacent block' control for streets gives you greater control over perimeter and mixed-use blocks, allowing you to create L-shaped or U-shaped perimeter blocks, as well as lanes through housing blocks.</li>
          <li>
            The block and route shading can now be fully customized and you can set specific colours for specific values or ranges.
            For example, you can choose a colour to highlight all buildings between 3 and 5 storeys.
          </li>
          <li>
            The ground plane image import system has been improved.
            You can now 'update' an image from another file without having to delete and re-import it.
            You can also now easily add a second image at exactly the same location as an image already in the model.
          </li>
        </ol>
      </React.Fragment>
    )
  }, // CityCAD 2.4
  {
    id: 26,
    date: "2013-07-08",
    title: "CityCAD 2.5 Released",
    image: "/images/citycad_25_aerial.png",
    content: (
      <React.Fragment>
        <h3>New license management system</h3>
        <p>We are pleased to announce the release of CityCAD 2.5, five years since we launched the first version in 2008.</p>
        <p>The most significant upgrade is a new license management system. From a secure login area on our website, you can now:</p>
        <ul>
          <li>view and manage your CityCAD licenses</li>
          <li>purchase, renew or cancel your existing licenses</li>
          <li>request quotes</li>
          <li>view billing information and download invoices for each of your orders</li>
          <li>access an archive of account emails that have been sent to you</li>
          <li>submit and view support tickets, and discuss issues with other users in your organization</li>
        </ul>
        <p>
          This new licensing database is fully integrated with CityCAD, so you can purchase, activate and deactivate your copies automatically.
          The new system also makes it easy to transfer licenses between PCs in your office as needed.
        </p>
        <p>
          If you have an active CityCAD subscription we will be contacting you separately with details of how to access your user area.
        </p>
        <h3>New city modelling and analysis features</h3>
        <p>CityCAD 2.5 includes several new features</p>
        <ul>
          <li>All CityCAD Help articles are now online to ensure that you are viewing the most up-to-date information, and all articles can be viewed in the City Design Assistant panel.</li>
          <li>A new search feature has been included in the Help system to make it quicker to find the article you are looking for.</li>
          <li>New HD video tutorials have been prepared with audio description, and have been uploaded to our YouTube page.</li>
          <li>Urban Resource (.urbresource) files are new utility files which can make it easier to exchange information and custom land use settings with other members of your team.</li>
          <li>A new 'car park' category for custom land uses makes it easier to create and account for several different types of parking - for example multi-storey, deck and surface parking - in your model.</li>
          <li>
            New online database resources for sites in the UK have been added to the online database in the City Design Assistant panel.
            The plan is to expand this to other countries and regions.
            Please contact us with any suggestions of information resources that would be useful to you.
          </li>
        </ul>
        <p>For CityCAD 2.5, we have reset the trial so you can download and activate a 30-day free trial even if you have previously used a free trial on an earlier version.</p>
        <p>Download your 30-day free trial</p>
        <h3>We've moved office!</h3>
        <p>Please note we've recently moved to larger offices in London's Tech City area:</p>
        <p>
          Suite 406<br />
          Davina House<br />
          137-149 Goswell Road<br />
          London<br />
          EC1V 7ET
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.5
  {
    id: 27,
    date:"2014-02-28",
    title: "CityCAD 2.6 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <h3>See what's new in CityCAD 2.6</h3>
        <p>We've made several improvements to CityCAD with this release:</p>
        <ul>
          <li>New samples have been added to the settings library (different kinds of residential and mixed-use blocks with a variety of detailed building perimeter objects) to make it easier to get up and running quickly.</li>
          <li>New mixed-use floors within buildings and mixed-use perimeter blocks</li>
          <li>New variable-scope custom parameters</li>
          <li>New route and object libraries</li>
        </ul>
        <p>If you are an existing subscriber, please download and install the latest version. If you'd like to give the latest version a trial run, then you can download it from here.</p>
        <p>We have extended our free academic licence programme and you can still get one of these from our online store.</p>
        <p>If you are considering buying a commercial licence, significant discounts are available for smaller organizations with 10 people or less.</p>
      </React.Fragment>
    )
  }, // CityCAD 2.6
  {
    id: 28,
    date: "2014-09-22",
    title: "CityCAD 2.6.10 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>A new version of CityCAD has been released. Your license entitles you to free updates and this is available for download from our website.</p>
        <p>
          CityCAD 2.6.10 includes several performance and stability upgrades, updated online resources and an improved installation system.
          When convenient, please download and install this latest version.
          When you run the new setup file, it will automatically update your copy of CityCAD to the latest version, and your license activation will be unaffected.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.6.10
  {
    id: 29,
    date: "2015-03-20",
    title: "CityCAD 2.7 Released",
    image: "/images/citycad-2-7-0.png",
    content: (
      <React.Fragment>
        <p>We're pleased to let you know that CityCAD 2.7 has been released and is now available for download on our website.</p>
        <p>This introduces new snaps and tools to help you draw streets more easily, quickly and precisely, including:</p>
        <ul>
          <li>New drawing constraints for drawing streets at fixed angles from existing streets</li>
          <li>Offset tool for easily creating a copy of a route at a specified distance from the original</li>
          <li>Fillet tool for extending routes and joining them at their intersection point</li>
          <li>Extend tool for extending streets to specified limits</li>
          <li>New inferred snaps to points away from the line you are drawing</li>
        </ul>
        <p>
          Updates are free of charge to CityCAD subscribers. When convenient, please download and run the setup file to update your existing installation.
          Your license will be unaffected.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.7
  {
    id: 30,
    date: "2015-08-28",
    title: "CityCAD 2.7.3 Released",
    image: "/images/citycad-2-7-3-new_visualization.png",
    content: (
      <React.Fragment>
        <p>We've been working on several improvements to CityCAD and today we have released a new version.</p>
        <h3>Enhanced visualization features</h3>
        <ul>
          <li>Specular highlights (sun reflection) on all surfaces in the model</li>
          <li>Ambient occlusion shading to improve perception of shapes</li>
          <li>Customizeable brightness settings in display preferences</li>
        </ul>
        <h3>Performance and stability improvements</h3>
        <ul>
          <li>New memory management system for ground plane images</li>
          <li>Improved DXF interchange system with more efficient export and a new option to export only individual trees, route or block interior trees in the DXF model.</li>
          <li>Some minor bug fixes.</li>
        </ul>
      </React.Fragment>
    )
  }, // CityCAD 2.7.3
  {
    id: 31,
    date: "2015-09-29",
    title: "CityCAD Webinar",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          Next week we will be running a webinar.
          This will be a live on-screen demonstration to introduce CityCAD - how to quickly sketch an urban masterplan and estimate quantities from it.
          The demo will last about 20 minutes and will be in three parts:
        </p>
        <ul>
          <li>Importing base information</li>
          <li>Sketching a masterplan design option</li>
          <li>Exporting a summary with quantities and other information</li>
        </ul>
        <p>This will be at 12:00pm UK time and we will leave some time for questions afterwards.</p>
      </React.Fragment>
    )
  }, // Webinar
  {
    id: 32,
    date: "2016-03-18",
    title: "CityCAD 2.7.6 Released",
    image: "/images/citycad-2-7-6-copy-settings.png",
    content: (
      <React.Fragment>
        <p></p>
        <p>
          A new version of CityCAD is available from our website.
          As a CityCAD subscriber, you can upgrade to this new version free of charge and your license will be unaffected. When convenient, please go to the link above and download and run the setup file.
        </p>
        <p>
          CityCAD 2.7.6 includes a number of performance enhancements, bug fixes (including a fix to the image export feature), as well as a new custom Copy Settings feature which makes it easier to copy settings from a floor to a range of floor (e.g. 4th to 7th floors) in any building in your model.
        </p>
        <p>
          Thank you again for using CityCAD. As always please contact us at support@holisticcity.co.uk if we can help with any questions.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.7.6
  {
    id: 33,
    date: "2018-05-23",
    title: "CityCAD 2.8.2 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          We have released version 2.8.2 of CityCAD, our conceptual urban masterplanning application.
        </p>
        <p>This is a maintenance release with performance improvements and minor bug fixes.
          CityCAD 2.8.2 also supports our City Information Modelling Toolkit.
          Contact us for more information.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.8.2
  {
    id: 34,
    date: "2019-01-29",
    title: "We have moved office",
    image: "/images/graysinnroad.jpg",
    content: (
      <React.Fragment>
        <p>
          From 1 February 2019 our main office will be located at: 60 Gray’s Inn Road LONDON WC1X 8AQ.
          Please contact us at info@holisticcity.co.uk if you have any questions about this move.
          We look forward to welcoming our clients to the new office."
        </p>
      </React.Fragment>
    )
  }, // We have moved office
  {
    id: 35,
    date: "2019-01-29",
    title: "CityCAD 2.8.3 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          We have released version 2.8.3 of CityCAD, our conceptual urban masterplanning application.
          This is a maintenance release with minor changes and bug fixes.
          You can download the setup file to install the latest version and your licence will be unaffected.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.8.3
  {
    id: 36,
    date: "2019-05-23",
    title: "New CityCAD Online panel",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>We have made some changes to the ‘CityCAD Online’ panel to make it quicker to search for help articles and other online resources.</p>
        <p>Just type what you are looking for – for example ‘Route Tool’ – and a list of help articles will appear below as you type.</p>
        <p>You can also type:</p>
        <ul>
          <li>
            The name of a city e.g. London.
            If it is in our database you can click a link to view examples of streets, urban spaces and housing projects.
          </li>
          <li>The name of a street or urban space e.g. ‘Oxford Street’.</li>
          <li>The architect of a housing project.</li>
        </ul>
        <p>Results will appear below as you type. We have made a quick movie below to show how it works.</p>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/-VRTLZtaucc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
        </iframe>
        <p>The new search is available in the ‘CityCAD Online’ panel on the left side when you open CityCAD,.</p>
      </React.Fragment>
    )
  }, // New CityCAD Online Panel
  {
    id: 37,
    date: "2019-07-09",
    title: "CityCAD 2.8.4 Released",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>
          We have released CityCAD 2.8.4.
          This is a maintenance release which resolves graphics hardware display issues experienced by some customers.
        </p>
        <p>
          In the next few weeks we will be releasing more details of CityCAD 3.0 which will be a major new release later in 2019.
          Please get in touch if you would be interested in joining our beta test programme.
        </p>
      </React.Fragment>
    )
  }, // CityCAD 2.8.4
  {
    id: 38,
    date: "2020-02-21",
    title: "CityCAD 3.0 Released",
    image: "/images/00027_main_display_window.png",
    content: (
      <React.Fragment>
        <p>Ten years after CityCAD 2.0, we are pleased to announce the release of CityCAD 3.0.</p>
        <p>
          If you are an existing subscriber, please download and update your version free of charge.
          If you have not used CityCAD before, this is a great time to download a 30-day free trial.
        </p>
        <h3>Whats's new?</h3>
        <ul>
          <li>
            CityCAD is now a 64-bit application which should eliminate memory errors when working on large models.
            Please note if you already have CityCAD 2.8 installed, then 3.0 will be installed as a separate application.
          </li>
          <li>
            CityCAD is now integrated with secure cloud storage, making it easier to share your model and collaborate with colleagues.
            You will need to log in using your HCL account to use this feature.
          </li>
          <li>
            CityCAD includes a growing online library of components including housing types and urban objects which you can easily add to your masterplan models.
            You will need to log in using your HCL account to use this feature.
          </li>
          <li>There is a new, higher performance user interface including a ribbon toolbar and new search features in the city grid.</li>
          <li>You can now customize the labels shown in the 3D view – you can enter these manually or display values such as parking requirements.</li>
          <li>In the CityCAD Online panel you can now search for locations and automatically set the geographic location of your model.</li>
          <li>As with all new versions, we have made miscellaneous improvements to performance and stability.</li>
        </ul>
        <h3>New licences</h3>
        <p>We have made some changes to the subscriptions we offer for new users, although if you are an existing subscriber your subscription has not been changed.</p>
        <p>We have also retained the free academic licence.</p>
        <h3>Changes include</h3>
        <p>
          New Planning Team Package – this is specially designed for planning authorities in the UK who would like to take advantage of CityCAD but do not have time to learn the software in detail. The planning team package includes on-site assistance with installation, training and model setup, and several follow-up visits each year. This allows you to use CityCAD for site capacity studies, testing site-specific development policies, calculating quantities for environmental impact assessments and other tasks without having to invest significant time in training and modelling.
        </p>
        <p>
          Adjustments to number of users – our ‘standard flexible’ subscription is now called a ‘Team Subscription’, and we have expanded the number of concurrent users to 20.
          We have also introduced a new ‘Enterprise’ subscription which can be used by up to 50 concurrent users within the same organization.
        </p>
        <p>
          For full details please see our online store
        </p>
      </React.Fragment>
    )
  }, // CityCAD 3.0
  {
    id: 39,
    date: "2020-07-01",
    title: "New urban design database launched",
    image: "/images/database.png",
    content: (
      <React.Fragment>
        <p>If you are an urban designer, planner, engineer or architect, or if you’re just interested in cities, try our new urban design database: https://www.holisticcity.co.uk/database</p>
        <p>It’s a collection of over 3000 urban streets, public spaces, housing projects, street grids and junctions.</p>
        <p>The idea is to make it easy to find real-world examples of how designers dealt with a particular location, corridor width or size, to provide examples and inspiration for how a new place could be.</p>
        <p>
          You can browse these easily on a map, or you can search for particular types with numerical criteria.
          For example you can type ‘Show me streets about 10m wide’, or ‘Show me housing next to a railway’ or ‘Show me public spaces in Italy about 1.2ha’ and it will give you a list.
          Then click on a feature to see details.
        </p>
      </React.Fragment>
    )
  }, // New urban design database
  {
    id: 40,
    date: "2020-07-14",
    title: "Streetscape 2.0 Released",
    image: "/images/streetscape_intro_3.png",
    content: (
      <React.Fragment>
        <h3>Why Streetscape?</h3>
        <p>
          Getting the streetscape right continues to be one of the biggest challenges in urban developments.
          We focus on buildings, but still pay too little attention to the spaces between buildings, including streets.
        </p>
        <p>
          In order to help with this problem, we released the first version of Streetscape back in 2005 as a quick and simple way of testing design ideas for streets.
          Later, we included it in CityCAD as a cross-section editor for streets.
        </p>
        <p>
          For more guidance on designing residential streets, we recommend the Manual for Streets.
        </p>
        <p>
          You can browse these easily on a map, or you can search for particular types with numerical criteria.
          For example you can type ‘Show me streets about 10m wide’, or ‘Show me housing next to a railway’ or ‘Show me public spaces in Italy about 1.2ha’ and it will give you a list.
          Then click on a feature to see details.
        </p>
        <h3>What's new?</h3>
        <p>The basic idea is unchanged from 2005.</p>
        <p>
          First, create a set of ‘streetscape elements’ – pavements, vehicle lanes, bus lanes, cycle lanes, parking or reservations.
          Next, change their widths and arrange them in any order across the street.
          As you do this, Streetscape will automatically draw a cross-section and plan diagram so you can visualize the street environment in the context of buildings on either side.
          Finally, you can add advanced parameters such as the number of units per entrance and see analysis of how much on-street parking there is for each unit.
        </p>
        <p>So what’s changed?</p>
        <p>First, we have refreshed the user interface with a ribbon toolbar.</p>
        <p>Secondly, we have improved the graphics of pedestrians and vehicles in the dragrams, and added graphics for cyclists.</p>
        <p>
          Thirdly, we have integrated Streetscape with our urban design database.
          This means that if you are working on a design, and the corridor is 25m wide, you can quickly view a list of real-world streets of the same width.
        </p>
        <img src="/images/streetscape_intro_7b-1024x350.png" />
        <h3>How much does it cost?</h3>
        <p>We want to make it as easy as possible for consultants, planners and others to visualize complete street designs.</p>
        <p>Because of this, Streetscape remains completely free to use for both commercial and non-commercial use.</p>
        <h3>How can I get Streetscape?</h3>
        <p>
          Streetscape is available as a Windows app.
          To get the setup file, go to our downloads page.
        </p>
        <h3>Integration with CityCAD</h3>
        <img src="/images/streetscape-intro_8b-1024x570.png" />
        <p>
          You can still save your street designs as SSP files, and import them into CityCAD.
          After doing this you can include the design in your 3D model and add objects such as parklets and electric vehicle charging points.
        </p>
      </React.Fragment>
    )
  }, // Streetscape 2.0
  {
    id: 41,
    date: "2020-08-21",
    title: "CityCAD Introduction Webinar",
    image: "/images/citycad-planning-1.png",
    content: (
      <React.Fragment>
        <p>If you’d like to see a live webinar showing how our urban design software tool CityCAD can be used to explore and test design conceptual ideas for a development site, join us at 4pm (UK time) on Thursday 27th August.</p>
        <p>We’ve been working on ways to simplify the model-building process to make it easier for people who haven’t used CAD before to sketch and test ideas in 3D.</p>
        <p>
          A key part of this is expanding the library of buildings, housing blocks and street junctions.
          This means you can simply click to place tried-and-tested designs in your model.
          This library now includes a cycle-friendly roundabout and other street junctions, small playgrounds and housing types of different scales and densities.
        </p>
        <p>This August we are running three CityCAD promotions:</p>
        <ul>
          <li>
            An extended 3-month free trial for planning teams in the UK.
            We can offer technical support configuring land use categories and setting up development sites so that ideas can be tested.
          </li>
          <li>
            A 3-month promotion for monthly commercial licences.
            The first three months of any monthly subscription are available for £1 / month before reverting to the normal rate.
            These are flexible and can be cancelled at any time.
          </li>
          <li>
            A new discounted ‘graduate subscription’ for recent graduates.
            This is for people who may have used the free academic licence before but now want to use CityCAD on a commercial project.
          </li>
        </ul>
      </React.Fragment>
    )
  }, // CityCAD Introduction Webinar
  {
    id: 42,
    date: "2021-04-14",
    title: "CityCAD 3.1 subscription updates",
    image: "/images/release-placeholder.png",
    content: (
      <React.Fragment>
        <p>We are pleased to announce a new pricing structure for CityCAD, with commercial subscriptions now starting at £15+VAT or $20 per month.</p>
        <p>Since we first released CityCAD back in 2008, we have always offered pay-as-you-go subscription licences, because we know that sometimes it is only needed for a short time on a specific project.</p>
        <p>
          With the release of CityCAD 3.1, we took a decision to significantly reduce prices in order to make the software more accessible to a larger number of design practices and planning teams.
          These new licences still offer all of the previous features of CityCAD, including information-rich conceptual urban modelling and multi-disciplinary urban analysis features including GFA, unit mix, parking, sustainability and viability calculations.
        </p>
        <p>All subscriptions still include free upgrades for the duration of the subscription, cloud storage for CityCAD models, and the option to cancel at the end of each billing period.</p>

        <h3>Summary (UK prices in GBP)</h3>

        <TableContainer>
          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Licence</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Academic Subscription</TableCell>
                <TableCell component="th" scope="row">For academic, non-commercial use</TableCell>
                <TableCell component="th" scope="row">FREE</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Graduate Subscription</TableCell>
                <TableCell component="th" scope="row">For commercial use</TableCell>
                <TableCell component="th" scope="row">£45 for 1 year</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Single-user Subscription</TableCell>
                <TableCell component="th" scope="row">For commercial use.<br />2GB of cloud storage.</TableCell>
                <TableCell component="th" scope="row">£15 / month<br />£160 / year</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Team Subscription</TableCell>
                <TableCell component="th" scope="row">For commercial use by a team of up to 20 users.<br />Enhanced technical support and 100GB of cloud storage.</TableCell>
                <TableCell component="th" scope="row">£110 / month<br />£1,200 / year</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Enterprise  Subscription</TableCell>
                <TableCell component="th" scope="row">For commercial use by a team of up to 50 users.<br />Enhanced technical support and 250GB of cloud storage.</TableCell>
                <TableCell component="th" scope="row">£400 / month<br />£2,400 / year</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Planning Team Package</TableCell>
                <TableCell component="th" scope="row">
                  For planning policy and development control teams.<br />
                  Assistance with installation and training, project and model<br />
                  setup assistance. Dedicated technical support contact.
                </TableCell>
                <TableCell component="th" scope="row">Contact us for details</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <h3>Summary (prices in USD)</h3>

        <TableContainer>
          <Table sx={{ minWidth: 650, my: 2 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Licence</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Academic Subscription</TableCell>
                <TableCell component="th" scope="row">For academic, non-commercial use</TableCell>
                <TableCell component="th" scope="row">FREE</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Graduate Subscription</TableCell>
                <TableCell component="th" scope="row">For commercial use</TableCell>
                <TableCell component="th" scope="row">$60 for 1 year</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Single-user Subscription</TableCell>
                <TableCell component="th" scope="row">For commercial use.<br />2GB of cloud storage.</TableCell>
                <TableCell component="th" scope="row">$20 / month<br />$220 / year</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Team Subscription</TableCell>
                <TableCell component="th" scope="row">For commercial use by a team of up to 20 users.<br />Enhanced technical support and 100GB of cloud storage.</TableCell>
                <TableCell component="th" scope="row">$150 / month<br />$1,650 / year</TableCell>
              </TableRow>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Enterprise  Subscription</TableCell>
                <TableCell component="th" scope="row">For commercial use by a team of up to 50 users.<br />Enhanced technical support and 250GB of cloud storage.</TableCell>
                <TableCell component="th" scope="row">£$550 / month<br />$3,200 / year</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

      </React.Fragment>
    )
  }, // New £15 subscription
  {
    id: 43,
    date: "2022-01-14",
    title: "CityCAD 3.1.4 Release",
    image: "/images/citycad-3-1-4-barcelona-1024x599.png",
    content: (
      <React.Fragment>
        <p>
          The CityCAD 3.1.4 release is now available.
          If you have a CityCAD licence, please download and install this version.
        </p>
        <p>
          This is a minor upgrade, and includes a mix of new features and fixes to bugs reported by users.
          Please continue to get in touch with support@holisticcity.co.uk if you encounter any problems.
        </p>
        <p>
          Looking ahead, we would like to expand the analysis capabilities of CityCAD.
          Our development programme is guided by you and so please let us know if there are any features which would add value to your work.
        </p>
        <h3>CityCAD 3.1.4.1221 (64-bit) – Full release notes</h3>
        <ul>
          <li>
            In the CityCAD 3.1.4 release, the Online Library now supports ‘private’ libraries which you can access using your team login. We offer a service where we can create a custom library of urban design features. We can set these up so that you can access them from the city design assistant panel. For example, we can work with you to create a library of house types and set GFA, carbon, energy and cost values for each one.
            You can then place these easily on to site layouts to test ideas.
            To discuss your requirements please contact support@holisticcity.co.uk.
          </li>
          <li>
            We have added some more example blocks to the public library of urban design features.
            These include a typical block from the Barcelona extension which can be used to quickly test and compare ideas for large sites.
          </li>
          <li>We have added shortcuts to the Block and Route settings managers in the Modify ribbon toolbar.</li>
          <li>
            When exporting to DXF, streetscape elements (pavements, vehicle lanes, etc) will now have different face colours depending on their properties in CityCAD.
            Previously, these were all the same grey.
          </li>
          <li>In the start window, double clicking on a file in the recent items list will now open the file.</li>
          <li>
            We have fixed a bug with the transparency of hidden groups and phases.
            This occurred on some specific system configurations.
          </li>
          <li>The DXF Base Map import feature now supports a wider range of geometries.</li>
          <li>We have fixed a bug that occurred when importing specific DXF files.</li>
        </ul>
      </React.Fragment>
    )
  }, // CityCAD 3.1.4
  {
    id: 44,
    date: "2022-04-19",
    title: "CityCAD Technologies - our new company name",
    image: "/images/shard.jpeg",
    content: (
      <React.Fragment>
        <p>
          We are pleased to announce our change of company name from 'Holistic City' to 'CityCAD Technologies'.
        </p>
        <p>
          Since we launched back in 2005, the company has evolved in many ways but the aim has always been the same - to create technology that supports the development of sustainable and liveable cities.
        </p>
        <p>
          Our main product, CityCAD, has been at the core of most of our projects and we wanted our company name to reflect this.
        </p>
        <p>
          We'd like to take this opportunity to thank all current and past clients for your support and wish you all the best with your projects.
          We will continue to develop CityCAD as a tool for creating masterplans quickly and easily, and look forward to working with you in the future.
        </p>
        <h3>Details</h3>
        <p>
          From an administrative point of view, please be assured that this is just a change of name and office address.
        </p>
        <p>
          The company is still the same legal entity based in the UK and there has been no change of ownership.
          All subscriptions and other services will not be affected.
        </p>
        <p>
          If you have any queries about this change of name, or about anything else, please contact support@citycadtechnologies.co.uk.
        </p>
      </React.Fragment>
    )
  }, // CityCAD Technologies
  {
    id: 45,
    date: "2022-09-12",
    title: "CityCAD 3.2 Launched",
    image: "/images/glasgow-terrain.png",
    content: (
      <React.Fragment>
        <p>
          In CityCAD 3.2, we are introducing an exciting new feature to make it easier to work with terrain on sloping sites.
        </p>
        <p>
          Previously, you could adjust the individual level of route nodes, and import a 3D DXF mesh which could be converted into CityCAD routes.
        </p>
        <p>
          The simplified workflow is now:
        </p>
        <ul>
          <li>Create your model 'on the flat', by sketching over an image or importing CAD outlines.</li>
          <li>Import your terrain as 3D contour lines.</li>
          <li>Click the 'Match Terrain' button, and the levels in your CityCAD model will be adjusted to follow the terrain.</li>
          <li>If you need to edit your model, you can click 'Flatten Model' to bring all nodes back to the ground plane, to make it easier to edit.</li>
        </ul>
        <p>
          We would like to thank CityCAD users who we have worked with on this new feature, and we are always grateful for feedback.
          Please share your experiences of this new workflow and let us know what we can do to make CityCAD work better for you.
        </p>
      </React.Fragment>
    )
  } // CityCAD 3.2
];