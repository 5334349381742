import React from 'react';
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { colours } from "../../config";

const TitleCard = () => {
  return (
    <Card>
      <CardMedia
        component="img"
        sx={{ height: { xs: 250, md: 400} }}
        image="/images/barcelona.png"
        alt="View along Barceloneta street"
      />
      <CardContent>
        <Typography variant="h2" sx={{ textAlign: 'center', pt: { xs: 1, md: 4} }}>
          Technology for liveable cities
        </Typography>
        <Typography variant="h5" sx={{ textAlign: 'center', color: colours.darkGrey, pt: { xs: 1, md: 4} }}>
          We deliver digital solutions to help developers, urban planners and designers create sustainable, liveable and resilient cities.
        </Typography>
      </CardContent>
    </Card>
  )
}

export default TitleCard;