import React from 'react';
import { Breadcrumbs, Grid, Typography} from "@mui/material";
import { Link } from "react-router-dom";

import CategoryHeader from "../components/CategoryHeader";
import Clause from "../components/Clause";
import {COMPANY_NAME, COMPANY_ADDRESS, PRIVACY_POLICY_URL, SALES_EMAIL, SUPPORT_EMAIL, INFO_EMAIL} from "../config";

const ServicesTerms = () => {
  return(
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Breadcrumbs arial-label="breadcrumb" sx={{ mt: 2 }}>
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="text.primary">
            Services terms and conditions
          </Typography>
        </Breadcrumbs>
        {/*/>*/}
      </Grid>

      <CategoryHeader>Services terms and conditions</CategoryHeader>

      <Grid item xs={12}>
        <Typography paragraph sx={{ fontWeight: 'bold'}}>
          Please read these terms and conditions carefully as they contain important information about your rights and obligations when using CityCAD and our other solutions.
        </Typography>
        <Typography paragraph>
          Last updated: 21 March 2021
        </Typography>
        <Typography variant="h6">
          Summary
        </Typography>
      </Grid>

      <Clause reference="&bull;">
        <Typography>
          By installing, activating, or using the Services, you agree to the terms of this agreement.
        </Typography>
      </Clause>
      <Clause reference="&bull;">
        <Typography>
          This is a licence to use the Services, not a sale of a copy of any Service, and your use of the Services is subject to the terms and conditions of this licence agreement.
        </Typography>
      </Clause>
      <Clause reference="&bull;">
        <Typography>
          You can cancel any order for a licence within 14 days – more details in (5) below.
        </Typography>
      </Clause>
      <Clause reference="&bull;">
        <Typography>
          Please read the agreement below carefully, including the limited warranty and limitation of liability in (9) below.
          If you have any queries, then do not install, activate, or use any Services and contact {INFO_EMAIL} to seek clarification.
        </Typography>
      </Clause>

      <Clause reference="1">
        <Typography>Introduction and definitions</Typography>
      </Clause>
        <Clause reference="1.1">
          <Typography>
            This End User Services Agreement is not a sale of the Services or any copy of the Services.
            The terms of this End User Services Agreement (this ‘Agreement’) shall constitute a legal agreement between you and us ({COMPANY_NAME}, a company registered in England No. 5649954, Registered office: {COMPANY_ADDRESS}).
          </Typography>
        </Clause>
        <Clause reference="1.2">
          <Typography>
            In this Agreement, the ‘Services’ means all client-side and server-side software, data, documentation, written materials, files and accompanying information, including material downloaded from our website or delivered to you by us using other means.
            The Services include but are not limited to the CityCAD, Streetscape, Streetscape Pro and CityCAD Viewer applications, as well as the City Information Modelling Toolkit and AssetSIM online services.
          </Typography>
        </Clause>
        <Clause reference="1.3">
          <Typography>
            ‘Documentation’ means functional specifications accompanying the Services which describe their intended functionality.
            In the case of CityCAD, this means the help documentation which is accessible from within the software.
          </Typography>
        </Clause>
        <Clause reference="1.4">
          <Typography> ‘Privacy Policy’ means the privacy policy stored on our website at {PRIVACY_POLICY_URL}</Typography>
        </Clause>
        <Clause reference="1.5">
          <Typography sx={{ textTransform: 'uppercase'}}>
            By installing, activating or using the citycad software or any other Services, you confirm your acceptance of these terms and your Agreement to be a party to this binding contract.
            If you do not agree to all of the terms of this Agreement you must not install, activate or use the Services.
          </Typography>
        </Clause>

      <Clause reference="2">
        <Typography>Services</Typography>
      </Clause>
        <Clause reference="2.1">
          <Typography>
            We will provide the Services to you and subject to these terms you may install, activate, access, and use the Services if you do so lawfully, and in the form made accessible by us.
            You will be responsible for compliance with these terms by any other users who may have access to the Services through you.
            You may not sell or offer to resell the Services in whole or in part without our express written consent.
          </Typography>
        </Clause>
        <Clause reference="2.2">
          <Typography>
            You may need to set up an account and maintain internet access to use the Services.
            You agree to be responsible for the cost of maintaining this internet access.
          </Typography>
        </Clause>
        <Clause reference="2.3">
          <Typography>
            This Agreement contains terms and conditions for different types of licences and subscriptions for software products and services.
            In this Agreement, the ‘Licence’ means the specific licence type you have ordered from us, or the Free Trial licence if you are using the built-in trial activation feature.
            By ordering a specific licence type, you agree to the terms and conditions in this Agreement which are specific to the licence type you have ordered.
            Current licence types include the Free Trial Licence, Standard Flexible Subscription and Free Academic/Non-commercial Subscription.
          </Typography>
          <Typography paragraph>
            From time to time we may introduce other licence types.
            If you are using another type of licence then you agree to the terms and conditions of that licence which will be explained on our website or sent to you in writing.
          </Typography>
        </Clause>
        <Clause reference="2.4">
          <Typography>
            Please note that your use of the {COMPANY_NAME} website is subject to separate Terms and Conditions, which can be viewed on the website.
            Some software components – for example the City Design Assistant Panel in CityCAD – display content from the {COMPANY_NAME} website.
            This information is accessed and used subject to the Website Terms and Conditions.
          </Typography>
        </Clause>
        <Clause reference="2.5">
          <Typography>
            Technical Support by email is included in standard flexible subscriptions, as described in (7) below.
            Please note that telephone support subscriptions and support, training, consultancy or other services that may be provided by us or others will be subject to a separate agreement.
          </Typography>
        </Clause>
        <Clause reference="2.6">
          <Typography>
            The prices for Services advertised on our website constitute an ‘invitation to treat’ and are not a contractual offer.
          </Typography>
        </Clause>
        <Clause reference="2.7">
          <Typography>
            Subscription rates are subject to variation from time to time, although you will be notified by email at least three months before any change to your subscription rate.
            Your monthly subscription rate will not be changed in the first 12 months of a continuous monthly subscription period.
          </Typography>
        </Clause>

      <Clause reference="3">
        <Typography>Installation and activation</Typography>
      </Clause>
        <Clause reference="3.1">
          <Typography>
            You agree that we may use technological measures to prevent unlicensed or illegal use of the Services.
            This includes, but is not limited to, requiring you to log in to access the Services, and copy protection features on installed applications that limit the ability to use the Services.
          </Typography>
        </Clause>
        <Clause reference="3.2">
          <Typography>
            You agree that we may use information from your computer, including the system date and time, to prevent unlicensed or illegal use of the Services.
            We may store this information about your licence on the {COMPANY_NAME} server, which will be used if you activate Services on your computer automatically online.
          </Typography>
        </Clause>
        <Clause reference="3.3">
          <Typography>
            You agree that the Services may cease to operate if attempts are made to circumvent technological measures designed to protect unlicensed or illegal use of the Services including, but not limited to, adjustments to the date settings on a computer.
          </Typography>
        </Clause>
        <Clause reference="3.4">
          <Typography>
            Free Trial licence – The Services may be activated for a one-off trial period, after which the Services will cease to function.
          </Typography>
        </Clause>
        <Clause reference="3.5">
          <Typography>Licence order and activation process</Typography>
        </Clause>
          <Clause reference="3.5.1">
            <Typography>Either contact us by email or telephone to place an order, or order using our online store.</Typography>
          </Clause>
          <Clause reference="3.5.2">
            <Typography>
              You will be directed to order pages on our website secured by TLS.
              For payment, you will be redirected to a secure page on our card processing partner website (currently WorldPay).
              For recurring payments, you will be able to create a subscription agreement (currently a ‘FuturePay’ agreement with WorldPay) with our card processing partner.
              This is a separate agreement between you and our card processing partner to make recurring card payments.
            </Typography>
          </Clause>
          <Clause reference="3.5.3">
            <Typography>You will receive emails from us and our card processing partner, with order and payment information, and your activation key.</Typography>
          </Clause>
          <Clause reference="3.5.4">
            <Typography>
              If Services are not provided online, you may need to download the latest setup file from our website and install the Services if you have not already done so.
              For example, if using CityCAD you will need to go to the Product Activation window and enter your activation key.
            </Typography>
          </Clause>
          <Clause reference="3.5.5">
            <Typography>
              If your payment has been made successfully, or if a subscription agreement has been set up with our card processing partner, your licence will be activated automatically.
              Please wait until you receive the email from us with your activation key before attempting to activate.
            </Typography>
          </Clause>
          <Clause reference="3.5.6">
            <Typography>
              Your licence will begin on the day that you receive confirmation of payment.
              If you experience problems with automatic online activation of CityCAD or other offline Services, then you must let us know within 14 days and we can send you a manual activation code and reset the start of your licence to the day that we send you the manual activation code.
            </Typography>
          </Clause>
        <Clause reference="3.6">
          <Typography>You agree to pay for the chosen licence, and in return we offer you a non-exclusive, limited licence to use the Services subject to the terms and conditions in this Agreement.</Typography>
        </Clause>

      <Clause reference="4">
        <Typography>Delivery</Typography>
      </Clause>
        <Clause reference="4.1">
          <Typography>
            Please note that Services are normally delivered electronically either in browser or through the Downloads area of our website.
            You will not receive any goods by post.
          </Typography>
        </Clause>
        <Clause reference="4.2">
          <Typography>
            If you are unable to access the Services online and require a hard copy invoice and/or if you require any software or other material to be delivered on physical digital media, this can be requested by emailing {SALES_EMAIL}.
            We reserve the right to apply a reasonable duplication and packaging charge, as well as standard postage rates, but will ask you to confirm your agreement to any extra charges before they are incurred.
          </Typography>
        </Clause>

      <Clause reference="5">
        <Typography>Cancellation terms and refund policy</Typography>
      </Clause>
        <Clause reference="5.1">
          <Typography>
            You may cancel any licence within 14 days of first receiving confirmation of your licence being authorized.
            If you decide to cancel the licence, you must immediately deactivate and remove all copies of any software from your systems, and you may retain configuration files but not urban model files or any other information saved or exported while using the Services.
          </Typography>
        </Clause>
        <Clause reference="5.2">
          <Typography>
            If you have made a payment before you cancel a licence within the first 14 full days after a licence has been authorized for activation, then the amount of this payment will be refunded to you.
            We will process any refund due to you as soon as reasonably possible and, in any event, within 30 days of the day we receive your notice of cancellation.
          </Typography>
        </Clause>
        <Clause reference="5.3">
          <Typography>
            If you have signed up to a Standard Flexible Subscription licence, you may cancel your licence before the end of each billing period.
            You can do this by contacting us at {SUPPORT_EMAIL}, or by logging in to your account and cancelling your licence.
            Alternatively, you can give us notice by post or telephone and we will de-authorize your licence.
            If you decide to cancel a licence you can continue to use the Services until the end of the billing period and no refund will be given for the remainder of that billing period.
            When your copy expires, you must immediately remove all copies of Services from your systems.
            You may retain configuration files, Streetscape Pro files, urban model files, urban resource files, sample files and exported information on your system in case you decide to order another subscription later.
          </Typography>
        </Clause>

      <Clause reference="6">
        <Typography>Free academic licences</Typography>
      </Clause>
        <Clause reference="6.1">
          <Typography>
            You agree that copies of the Services used under a Free Academic Licence are only intended for strictly non-commercial purposes and agree that such copies will only be used for educational, non–commercial purposes.
            This includes use in schools, colleges and universities, libraries and museums.
            These licences may also be used in government agencies, professional institutions and training organizations if the Services are used wholly and exclusively for educational purposes.
          </Typography>
        </Clause>
        <Clause reference="6.2">
          <Typography>
            You agree that Free Academic Licences are only intended for use by those involved in the study of city design, planning and development.
            While it may be used on other areas of study, you agree that it is your responsibility to determine whether the Services are appropriate for the requirements of your course.
          </Typography>
        </Clause>

      <Clause reference="7">
        <Typography>Technical support</Typography>
      </Clause>
        <Clause reference="7.1">
          <Typography>General</Typography>
          <Typography paragraph>
            If you have purchased a Standard Flexible Subscription, you are entitled to technical support by email for the duration of the licence subject to the other terms in this Agreement.
            Any email support will normally only be provided between 9.00am and 5.30pm London(UK) time to assist in resolving problems and dealing with suspected faults in the Services.
            We aim to reply to most support requests within 1 hour, and in any event within 72 hours, but response times may vary, and we do not provide any guarantees as to response times.
          </Typography>
          <Typography paragraph>
            If you are using a Free Trial or Free Academic Licence, or any other kind of licence for which no payment has been received, we will normally aim to respond to requests for technical assistance, but these types of licence do not include any entitlement to technical support.
          </Typography>
        </Clause>
        <Clause reference="7.2">
          <Typography>Use of Latest Version of the Services</Typography>
          <Typography paragraph>
            From time to time, we may make available upgrades to the Services, which can be downloaded from our website.
            Although it is not a requirement of this Agreement, we strongly recommend that users always download and install the latest version to benefit from any new bug fixes, features and performance optimizations.
            If we believe that a technical problem reported to our support team might be caused by not using the latest version of the Services, then at our sole discretion we may decide not to provide technical support for that problem.
          </Typography>
        </Clause>
        <Clause reference="7.3">
          <Typography>Technical Support Fair Use Policy</Typography>
          <Typography paragraph>
            We ask that you consult the Documentation to see if a solution is available before you contact our technical support team.
            We may answer support requests by reference to the Documentation.
            We reserve the right to refuse to provide technical support if, in our sole discretion, we believe that an excessive quantity of requests for support are being made, which could be reasonably answered by reference to the Documentation and other sources.
          </Typography>
        </Clause>
        <Clause reference="7.4">
          <Typography>Special agreements</Typography>
          <Typography paragraph>
            A separate agreement may be set up between you and us to provide premium technical support services to you by telephone, email and, by mutual agreement and where convenient for both parties, by any other form of communication including video conferencing or online chat.
            Any such agreement will be separate to this Agreement.
            Please contact {SALES_EMAIL} if you would like more details.
          </Typography>
        </Clause>
        <Clause reference="7.5">
          <Typography>Perpetual licences</Typography>
          <Typography paragraph>
            If you buy a Perpetual licence, then we would normally aim to provide technical assistance if requested, but this type of licence does not include any entitlement to technical support.
          </Typography>
        </Clause>

      <Clause reference="8">
        <Typography>Ownership</Typography>
      </Clause>
        <Clause reference="8.1">
          <Typography>
            You acknowledge that the Services, their copyright, their trademarks, and any other intellectual property rights in the Services are owned by us.
            You acquire no ownership of any part of the Services from this Agreement and no right to use the Services except subject to the terms of this Agreement.
          </Typography>
        </Clause>
        <Clause reference="8.2">
          <Typography>
            You acquire no right to copy the Services, prepare derivative works, or participate in any development, manufacturing, marketing, and maintenance of the Services.
          </Typography>
        </Clause>
        <Clause reference="8.3">
          <Typography>
            The Services and related materials are protected by copyright law and international copyright treaties.
            You may not disassemble, decompile, reverse engineer, redistribute(in any form) or modify the Services in any way at any time.
          </Typography>
        </Clause>

      <Clause reference="9">
        <Typography>Limited Warranty and Limitation of Liability</Typography>
      </Clause>
        <Clause reference="9.1">
          <Typography>
            We warrant that the Services will perform substantially in accordance with the description provided in online documentation for a period of ninety days from the start of your licence, or until your licence ends, whichever is the sooner.
            You agree that, as when using any complex digital applications, there is a possibility of occasional faults and unexpected results when using the Services, and you agree not to use the Services in any environment requiring fail-safe performance in which the failure of the Services could lead directly to death, personal injury or significant physical or environmental damage.
          </Typography>
        </Clause>
        <Clause reference="9.2">
          <Typography>
            If, during the ninety days from the start of your licence, you determine that the Services do not operate substantially in accordance with the documentation, then you should promptly notify us by email or in writing, specifying the non-conformance with the documentation in sufficient detail that it can be reproduced by our technical support team.
          </Typography>
        </Clause>
        <Clause reference="9.3">
          <Typography>
            If your computer equipment meets the minimum specifications for use of the Services, as specified in the Documentation, we will make reasonable efforts to bring the Services in conformance with the documentation and to provide you with a corrected version, or an alternative means of accomplishing the same or equivalent functionality, as soon as reasonably possible.
            If, for any reason, we are unable to do this, then we reserve the right to terminate this agreement and refund you with the price paid for the licence.
          </Typography>
        </Clause>
        <Clause reference="9.4">
          <Typography>You agree not to allow any third party to use the Services on your hardware or otherwise and to indemnify and hold us harmless from any damages or claims arising from use by any third party.</Typography>
        </Clause>
        <Clause reference="9.5">
          <Typography>You agree to back up data regularly and take other appropriate measures to protect your programs and data.</Typography>
        </Clause>
        <Clause reference="9.6">
          <Typography sx={{textTransform: 'uppercase'}}>
            In no event will we be liable for any damages, including but not limited to any loss of income or revenue, loss of business, loss of profits or contracts, loss of anticipated savings, loss of data, waste of management or office time or any indirect or consequential loss or damage of any kind howsoever arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable or if we are advised of the possibility of such damages, and we will not be responsible for any failure to perform, or delay in performance of, any of our obligations under this agreement caused by events outside our reasonable control, or for any other claim by you.
          </Typography>
        </Clause>
        <Clause reference="9.7">
          <Typography>
            In any case our entire liability shall be limited to the amount actually paid by you for your licence.
            These limitations do not apply to any liabilities that cannot be excluded by applicable laws.
          </Typography>
        </Clause>
        <Clause reference="9.8">
          <Typography>
            Except for the educational licences, the Services are designed to be used only by qualified city design, planning and development professionals, and unless we explicitly advise otherwise in writing they are only intended for broad, strategic, conceptual modelling of masterplans.
            The Services use many assumptions to calculate data, and it is your responsibility to determine whether the Services are the appropriate tool for the project for which it is being used.
          </Typography>
        </Clause>
        <Clause reference="9.9">
          <Typography>
            You understand that the Services are not designed for detailed design work.
            While the Services may provide a reasonably accurate representation of a masterplan, it is not a perfect model and all outputs – for example number of units and floor areas – should be treated as indicative and only used to investigate broad design scenarios at the feasibility stage, equivalent to RIBA (Royal Institute of British Architects) Plan of Work Stages 1 and 2.
            They should not be used for detailed design or construction work under any circumstances.
            Images generated by the Services should not be relied upon as Accurate Visual Representations or used in the precise analysis of significant views.
          </Typography>
        </Clause>
        <Clause reference="9.10">
          <Typography>
            Most outputs of the Services including but not limited to numbers of units, parking spaces and population numbers are estimates and should not be treated as exact.
            The exact numbers will require a detailed design exercise and may differ significantly from the estimate provided by the Services.
          </Typography>
        </Clause>
        <Clause reference="9.11">
          <Typography sx={{textTransform: 'uppercase'}}>
            The regulations governing city design, planning and development are complex, and vary throughout the world.
            Use of the services does not guarantee compliance with local planning, highways, zoning, building control or other applicable regulations.
            The services are no substitute for professional judgement.
            If you are in any doubt you should seek advice from a qualified urban designer, architect, engineer, surveyor or other professional appropriate to your project.
          </Typography>
        </Clause>
        <Clause reference="9.12">
          <Typography>
            You may not use the Services for any purposes which are illegal or unauthorized under the law of England and Wales including, but not limited to, violation of copyright or trademark laws.
            You agree to indemnify and hold us harmless from any damages or claims arising from any use of the Services by you for such illegal or unauthorized purposes.
          </Typography>
        </Clause>

      <Clause reference="10">
        <Typography>No obligation on account of information provided by you</Typography>
      </Clause>
        <Clause reference="10.1">
          <Typography>We do not want to receive, and you agree not to disclose to us any information that is confidential or proprietary to you or others.</Typography>
        </Clause>
        <Clause reference="10.2">
          <Typography>
            You agree that the contents of all oral and written reports to us and any other materials, information, suggested features, ideas, concepts, and know-how provided by you (including any corrected faults in the Services) become the property of us for all business purposes, without any accounting or any payment to you.
            We agree to take reasonable steps to protect the confidentiality of any CityCAD models or other outputs from the Services received by us from you for technical support purposes.
          </Typography>
        </Clause>
        <Clause reference="10.3">
          <Typography>
            Under no circumstances will we become liable for any payment to you for any information that you provide, whether concerning the Services or otherwise, no matter how such information is used or exploited by us or anyone else, except as may be separately agreed in writing.
          </Typography>
        </Clause>

      <Clause reference="11">
        <Typography>Customized plugins to CityCAD and other services</Typography>
        <Typography paragraph>
          For the avoidance of doubt, this Agreement only relates to the Services, and does not relate to any non-standard customized software components that operate alongside the Services, that are not available as part of the standard CityCAD installation, and which may be developed by us or others.
          Licensing or sale of any customized plugins may be covered by a separate agreement.
        </Typography>
      </Clause>

      <Clause reference="12">
        <Typography>Content</Typography>
      </Clause>
        <Clause reference="12.1">
          <Typography>
            As part of the Services, you may be able to store information such as CityCAD models, images and other files in a secure online storage service.
          </Typography>
        </Clause>
        <Clause reference="12.2">
          <Typography>
            You retain ownership and responsibility for any content uploaded as part of your use of the Services and agree that you will take responsibility for any information uploaded by you or any other team members on your account.
          </Typography>
        </Clause>
        <Clause reference="12.3">
          <Typography>
            We will not access, modify or delete your information except as may be required by law, or as may be necessary to provide technical support services to you on request, or as may be necessary to perform maintenance or to deliver the services, or in order to process orders and account closures, or as otherwise may be agreed in writing with you.
          </Typography>
        </Clause>
        <Clause reference="12.4">
          <Typography>
            You agree that information may be stored on servers belonging to a third party.
            While our intention is to routinely store all data within the UK, you agree that there is a possibility that some information may be transmitted outside of the UK for backup or other purposes.
          </Typography>
        </Clause>
        <Clause reference="12.5">
          <Typography>
            You agree that we and third-party cloud storage providers may back up your information and that your information may persist for a period in backup storage after you have deleted files from your account.
          </Typography>
        </Clause>
        <Clause reference="12.6">
          <Typography>
            If using the experimental (beta) map feature when creating new models in CityCAD, you agree that this is an experimental feature in development that was released for the purposes of inviting user feedback, that access to this feature is not guaranteed, that it is not intended for commercial use, and the number of images that can be viewed may be restricted.
            You agree not to store, save, print, or redistribute map content from this service.
          </Typography>
        </Clause>

      <Clause reference="13">
        <Typography>Privacy</Typography>
      </Clause>
      <Clause reference="13.1">
        <Typography>You understand and agree that by using the Services, you consent to the collection, use, processing and storage of personal information in accordance with our Privacy Policy.</Typography>
      </Clause>
      <Clause reference="13.2">
        <Typography>
          You agree that you are responsible for compliance with all applicable privacy and data protection laws relating to personal information stored by you as part of your use of the Services, and that you will ensure you have the required consent before storing personal information as part of your use of the Services.
        </Typography>
      </Clause>
      <Clause reference="13.3">
        <Typography>
          You agree and understand that any data storage functionality that is offered as part of the Services is not intended for the storage of social security numbers, credit or debit card numbers, financial account numbers, driving licence details, medical information, health insurance information, sensitive data about personal characteristics such as race, religion or sexual orientation, or other personal data that may pose a risk of harm if improperly disclosed.
          You agree not to upload or otherwise submit or continue to store any such information in connection with the Services and agree that we will have no responsibility or liability with respect to such information, except as required by law.
        </Typography>
      </Clause>

      <Clause reference="14">
        <Typography>General</Typography>
      </Clause>
      <Clause reference="14.1">
        <Typography>
          Please note that we may not file a copy of this Agreement.
          We may from time to time update the version of this Agreement on our website and stored within software within our Services, and we do not guarantee that the version you have agreed to will remain accessible.
          We therefore recommend that you download, print, and retain a copy of this Agreement for your own records.
        </Typography>
      </Clause>
      <Clause reference="14.2">
        <Typography>
          No agency, partnership, joint venture, or similar relationship is created by this Agreement.
          For the avoidance of doubt this Agreement is not exclusive and we may enter the same, different, or similar agreements with others.
        </Typography>
      </Clause>
      <Clause reference="14.3">
        <Typography>
          If any translations of this Agreement are offered and prepared, the English version of this Agreement is binding in case of any inconsistencies between it and any future translations.
        </Typography>
      </Clause>
      <Clause reference="14.4">
        <Typography>
          Except as described in (7.4) and (11) above, this Agreement and the Documentation provided with the Services constitute the entire agreement between us and you in relation to the Services, and supersede any previous agreements, arrangements or understandings including licence agreements made during beta test programmes.
        </Typography>
        <Typography paragraph>
          We and you both acknowledge that, in entering into this Agreement, we or you do not rely on any statement, representation, assurance or warranty, whether it was made innocently or negligently, of any person, whether a party to this Agreement or not, other than expressly as set out in this Agreement.
          Any change or addition to this Agreement must be in writing and signed by both you and one of our directors.
        </Typography>
      </Clause>
      <Clause reference="14.5">
        <Typography>
          You may transfer this Agreement to another party if the other party agrees to the terms and conditions of this agreement, and you inform us by emailing {SALES_EMAIL} with the contact details of the other party, and if you then deactivate your copies of the Services, send us the deactivation code and then remove all copies of the Services in your possession.
          You may retain CityCAD configuration files, saved models and other information exported from the Services.
          You may not transfer this Agreement or any portion thereof except as described in this Agreement.
          We shall have the right to freely assign or transfer this Agreement.
        </Typography>
      </Clause>
      <Clause reference="14.6">
        <Typography>
          As a reward for reading this far into the Agreement, we agree to offer you on request a 20% discount on your next order from us up to a maximum discount of GBP 1500 or equivalent in another currency.
          This offer can only be claimed once by any person, and by any organization, and will expire on 31 December 2022.
          We reserve the absolute right to discontinue this offer at any time and for any reason prior to this date.
          To claim this offer, write to {SALES_EMAIL} with details of the licence or licences you would like to order, and quoting clause 14.6 of the {COMPANY_NAME} End User Services Agreement.
        </Typography>
      </Clause>
      <Clause reference="14.7">
        <Typography>
          This Agreement, and any non-contractual obligations arising out of or in connection with this Agreement, are governed by the law of England.
          We and you irrevocably submit to the exclusive jurisdiction of the English Courts for the determination of disputes arising under this contract.
        </Typography>
      </Clause>

    </Grid>
  )
}

export default ServicesTerms;