import { createTheme } from "@mui/material";

export const colours = {
  bluePrimary: '#006cf0',
  medBlue: '#479aff',
  lightBlue: '#99c7ff',
  white: '#f0eff4',
  lightGrey: '#d1d5de',
  medGrey: '#707070',
  darkGrey: '#414141',
  darkPurple: '#210124',
  citycadGreen: '#669933'
}

export const theme = createTheme({
  palette: {
    background: {
      default: colours.white
    }
  },
  typography: {
    fontFamily: [
      'sans-serif',
      'Poppins'
    ],
    h2: {
      fontSize: '3.8rem',
      '@media (max-width: 600px)': {
        fontSize: '2.5rem'
      }
    }
  }
})

export const COMPANY_NAME = "CityCAD Technologies Limited";
export const COMPANY_ADDRESS = "85 Great Portland Street, First Floor, London W1W 7LT";
export const PRIVACY_POLICY_URL = "https://www.citycad.co.uk/privacy-policy";
export const SALES_EMAIL = "sales@citycadtechnologies.co.uk";
export const SUPPORT_EMAIL = "support@citycadtechnologies.co.uk";
export const INFO_EMAIL = "info@citycadtechnologies.co.uk";
export const PRIVACY_EMAIL = "privacy@citycadtechnologies.co.uk";

export const BROWSE_PRODUCTS_AND_SERVICES_URL = "https://www.holisticcity.co.uk/client/cart.php?gid=1";
export const CITYCAD_SETUP_FILE_URL = "https://storage.googleapis.com/citycad-technologies-assets/CityCAD%203.2.1.726%20x64%20Setup.exe";
export const STREETSCAPE_SETUP_FILE_URL = "https://storage.googleapis.com/citycad-technologies-assets/StreetscapePro%202.0.1.1005%20Setup.exe";