import React from 'react';
import {Breadcrumbs, Grid, Typography} from "@mui/material";
import { Link } from "react-router-dom";

import CategoryHeader from "../components/CategoryHeader";
import Clause from "../components/Clause";
import {COMPANY_NAME, COMPANY_ADDRESS, PRIVACY_POLICY_URL} from "../config";

const WebsiteTerms = () => {
  return(
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Breadcrumbs arial-label="breadcrumb" sx={{ mt: 2 }}>
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Typography color="text.primary">
            Website terms of use
          </Typography>
        </Breadcrumbs>
        {/*/>*/}
      </Grid>

      <CategoryHeader>Website terms of use</CategoryHeader>

      <Grid item xs={12}>
        <Typography paragraph sx={{ fontWeight: 'bold'}}>
          Please read these terms and conditions (these ‘Terms’) carefully as they contain important information about your rights and obligations when using this website (the ‘Website’) and in particular clause 10.6 and 10.7.
          You should print a copy of these terms for future reference.
        </Typography>
        <Typography paragraph>
          Updated: 24 May 2019<br />
          Updated: 1 February 2019 (change of address)
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography paragraph>
          We, {COMPANY_NAME}, own and operate this Website.
          We’re a limited company registered in England and Wales under company number: 5649954 having our registered office at {COMPANY_ADDRESS}.
          Our VAT Number is: GB926951787.
        </Typography>
      </Grid>

      <Clause reference="1">
        <Typography>How these Terms apply</Typography>
      </Clause>
        <Clause reference="1.1">
          <Typography>
            By using the Website you are agreeing to comply with and be bound by these Terms regarding the use of the Website in consideration for us allowing you to access and use the Website.
            You must not use the Website if you do not agree to comply with and be bound by these Terms.
          </Typography>
        </Clause>
        <Clause reference="1.2">
          <Typography>
            Use of the Website includes accessing, browsing or registering for the Website.
          </Typography>
        </Clause>
        <Clause reference="1.3">
          <Typography>
            We reserve the right to change these Terms at any time.
            We will take reasonable steps to make you aware of any changes to the Terms, for example by posting them on the Website.
            You agree to be bound by the version of these Terms displayed on the Website at the time you use it.
          </Typography>
        </Clause>
        <Clause reference="1.4">
          <Typography>
            These Terms refer to the following, which also apply when using this Website:
          </Typography>
        </Clause>
          <Clause reference="1.4.1">
            <Typography>
              Privacy policy, which can be found at {PRIVACY_POLICY_URL}
            </Typography>
          </Clause>
          <Clause reference="1.4.2">
            <Typography>
              Services terms and conditions, which can be found at
            </Typography>
          </Clause>

      <Clause reference="2">
        <Typography>Access</Typography>
      </Clause>
        <Clause reference="2.1">
          <Typography>You are responsible for making all arrangements necessary for you to have access to the Website.</Typography>
        </Clause>
        <Clause reference="2.2">
          <Typography>You are responsible for ensuring that all persons who access the Website through your internet connection are aware of these Terms, and that they comply with them.</Typography>
        </Clause>
        <Clause reference="2.3">
          <Typography>
            We make reasonable efforts to ensure that the Website is available to view and use 24 hours a day throughout each year; however, this is not guaranteed.
            The Website may be temporarily unavailable at any time because of server or systems failure or other technical issues, or reasons that are beyond our control, required updating, maintenance or repair.
          </Typography>
        </Clause>
        <Clause reference="2.4">
          <Typography>Where possible, we will try to give you advance warning of maintenance issues but shall not be obliged to do so.</Typography>
        </Clause>

      <Clause reference="3">
        <Typography>Registering on this Website.</Typography>
      </Clause>
        <Clause reference="3.1">
          <Typography>By registering on the Website you undertake:</Typography>
        </Clause>
          <Clause reference="3.1.1">
            <Typography>That all the details you provide to us for the purpose of registering on the Website are accurate and complete in all respects.</Typography>
          </Clause>
          <Clause reference="3.1.2">
            <Typography>You will notify us immediately of any changes to the information provided on registration.</Typography>
          </Clause>
          <Clause reference="3.1.3">
            <Typography>You are over 18 or if under 18 you have a parent or guardian&#8217;s permission to register with the Website under their supervision.</Typography>
          </Clause>
          <Clause reference="3.1.4">
            <Typography>To only use the Website using your own username and password.</Typography>
          </Clause>
          <Clause reference="3.1.5">
            <Typography>To make every effort to keep your password safe.</Typography>
          </Clause>
          <Clause reference="3.1.6">
            <Typography>Not to disclose your password to anyone.</Typography>
          </Clause>
          <Clause reference="3.1.7">
            <Typography>To change your password or to tell us immediately upon discovering that it has been used without your permission.</Typography>
          </Clause>
          <Clause reference="3.1.8">
            <Typography>To neither transfer or sell your username or password to anyone, nor permit, either directly or indirectly, anyone other than you to use them.</Typography>
          </Clause>
          <Clause reference="3.1.9">
            <Typography>To be responsible for all actions taken under your username and password.</Typography>
          </Clause>
        <Clause reference="3.2">
          <Typography>We reserve the right to suspend or terminate your access to the Website if you breach your undertakings in this clause.</Typography>
        </Clause>

      <Clause reference="4">
        <Typography>Intellectual property.</Typography>
      </Clause>
        <Clause reference="4.1">
          <Typography>
            In these Terms, Intellectual Property Rights mean copyright (including design copyrights), trade marks, patent, database and other intellectual property rights and similar proprietary rights which include, (without limitation), all rights in software, design, materials, works, techniques, computer programs, source codes, data, technical information, trading business brand names, goodwill, service marks, the style or presentation of the goods or services, creations, inventions or improvements upon or additions to an invention, confidential information, know-how and any research effort relating to {COMPANY_NAME}, moral rights and any similar rights in any country (whether registered or unregistered and including applications for and the right to apply for them in any part of the world).
          </Typography>
        </Clause>
        <Clause reference="4.2">
          <Typography>
            The software code contained in and related to the Website and the materials on the Website including text, images, videos, photographs and other materials are protected by Intellectual Property Rights owned by us and our licensors.
            You acknowledge that the Intellectual Property Rights in the Website and any software code used with and material supplied as part of the Website shall remain with us or our licensors.
          </Typography>
        </Clause>
        <Clause reference="4.3">
          <Typography>Subject to clause 5 you may</Typography>
        </Clause>
          <Clause reference="4.3.1">
            <Typography>retrieve and display materials on the Website on a computer screen;</Typography>
          </Clause>
          <Clause reference="4.3.2">
            <Typography>download and store in electronic form materials on the Website; and</Typography>
          </Clause>
          <Clause reference="4.3.3">
            <Typography>copy and print one copy only of materials on the Website.</Typography>
          </Clause>
        <Clause reference="4.4">
          <Typography>Copying, downloading, storing or printing the materials on the Website for any reason other than personal use or in accordance with clause 5.2.2 is expressly prohibited.</Typography>
        </Clause>
      <Clause reference="4.5">
        <Typography>You agree that you will not (and will not assist or facilitate any third party to) otherwise reproduce, modify, copy, distribute, transmit, publish, display, commercially exploit or create derivative works from any of the materials on the Website.</Typography>
      </Clause>
      <Clause reference="4.6">
        <Typography>No licence is granted to you to use any of our trade marks or those of our affiliated companies.</Typography>
      </Clause>
      <Clause reference="4.7">
        <Typography>You agree that you will not use our Intellectual Property Rights in any way other than allowed under these Terms and any infringement by you of our Intellectual Property Rights will be a breach of these Terms.</Typography>
      </Clause>

      <Clause reference="5">
        <Typography>Use of the Website.</Typography>
      </Clause>
      <Clause reference="5.1">
        <Typography>
          You are permitted to use the Website and the material contained in it only as expressly authorised by us and in accordance with these Terms as may be amended from time to time without notice to you.
        </Typography>
      </Clause>
      <Clause reference="5.2">
        <Typography>
          Provided that you comply with the other provisions of this clause, you may download or print one copy only of pages of our website:
        </Typography>
      </Clause>
        <Clause reference="5.2.1">
          <Typography>for your own private use; or</Typography>
        </Clause>
        <Clause reference="5.2.2">
          <Typography>to draw attention to the content of our website to members of your organisation.</Typography>
        </Clause>
      <Clause reference="5.3">
        <Typography>You must not:</Typography>
      </Clause>
        <Clause reference="5.3.1">
          <Typography>download or print pages of the Website for commercial use other than use permitted by clause 5.2.2;</Typography>
        </Clause>
        <Clause reference="5.3.2">
          <Typography>alter the content of any webpage you download or print; or</Typography>
        </Clause>
        <Clause reference="5.3.3">
          <Typography>use any images, videos or photographs on the webpage without the accompanying text.</Typography>
        </Clause>
      <Clause reference="5.4">
        <Typography>You must:</Typography>
      </Clause>
        <Clause reference="5.4.1">
          <Typography>keep intact all and any copyright and proprietary notices accompanying or attached to the materials on the Website you download, copy, store or print;</Typography>
        </Clause>
        <Clause reference="5.4.2">
          <Typography>acknowledge us as the owners of the content of the Website;</Typography>
        </Clause>
        <Clause reference="5.4.3">
          <Typography>erase any pages of the Website or materials on those pages downloaded other than in accordance with this clause; and</Typography>
        </Clause>
        <Clause reference="5.4.4">
          <Typography>destroy any pages of the Website or materials on those pages printed other than in accordance with this clause.</Typography>
        </Clause>
      <Clause reference="5.5">
        <Typography>
          You must not modify, adapt, translate, reverse engineer, decompile or disassemble any code or program used by or in connection with the Website.
          You must contact us to request our consent if you wish to take any such action to create an interoperable program and we may provide such consent if you provide us with the information we request.
        </Typography>
      </Clause>
      <Clause reference="5.6">
        <Typography>
          We provide access to and use of the Website on the basis that we exclude all representations, warranties and conditions to the maximum extent permitted by law.
        </Typography>
      </Clause>
      <Clause reference="5.7">
        <Typography>We reserve the right to:</Typography>
      </Clause>
        <Clause reference="5.7.1">
          <Typography>Make changes to the information or materials on this Website at any time</Typography>
        </Clause>
        <Clause reference="5.7.2">
          <Typography>Temporarily or permanently change, suspend or discontinue any aspect of the Website, including the availability of any features, information, database or content or restrict access to parts of or the entire Website without notice or liability to you or any third party.</Typography>
        </Clause>
        <Clause reference="5.7.3">
          <Typography>Refuse to post material on the Website or to remove material already posted on the Website.</Typography>
        </Clause>
      <Clause reference="5.8">
        <Typography>You may not use the Website for any of the following purposes:</Typography>
      </Clause>
        <Clause reference="5.8.1">
          <Typography>Disseminating any unlawful, harassing, libellous, abusive, threatening, fraudulent, malicious, harmful, vulgar, obscene, or otherwise objectionable material.</Typography>
        </Clause>
        <Clause reference="5.8.2">
          <Typography>Stalking, harassing, threatening, blackmailing any person or violating or interfering with the rights of any other person including their right to privacy.</Typography>
        </Clause>
        <Clause reference="5.8.3">
          <Typography>Transmitting material that encourages conduct that constitutes a criminal offence, results in civil liability or otherwise.</Typography>
        </Clause>
        <Clause reference="5.8.4">
          <Typography>Breaching any applicable local, national or international laws, regulations or code of practice.</Typography>
        </Clause>
        <Clause reference="5.8.5">
          <Typography>Gaining unauthorised access to other computer systems.</Typography>
        </Clause>
        <Clause reference="5.8.6">
          <Typography>Interfering with any other person&#8217;s use or enjoyment of the Website.</Typography>
        </Clause>
        <Clause reference="5.8.7">
          <Typography>Breaching any laws concerning the use of public telecommunications networks.</Typography>
        </Clause>
        <Clause reference="5.8.8">
          <Typography>Interfering with, disrupting or damaging networks or websites connected to the Website.</Typography>
        </Clause>
        <Clause reference="5.8.9">
          <Typography>
            Utilisation of data mining, robots or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of any substantial parts of the Website or the contents of the Website.
          </Typography>
        </Clause>
        <Clause reference="5.8.10">
          <Typography>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation.</Typography>
        </Clause>
        <Clause reference="5.8.11">
          <Typography>To create and/or publish your own database that features all or substantial parts of the Website or the contents of the Website.</Typography>
        </Clause>
        <Clause reference="5.8.12">
          <Typography>Making, transmitting or storing electronic copies of materials protected by copyright without the prior permission of the owner.</Typography>
        </Clause>
        <Clause reference="5.8.13">
          <Typography>Selling or re-selling or using for commercial purposes any of the content of or access to the Website or using for commercial purposes any of the content of or access to the Website.</Typography>
        </Clause>
        <Clause reference="5.8.14">
          <Typography>To represent to others that there is any connection between the Website and your business or your views and opinions or that we endorse you or anything connected to you.</Typography>
        </Clause>
      <Clause reference="5.9">
        <Typography>In addition, you must not</Typography>
      </Clause>
        <Clause reference="5.9.1">
          <Typography>Knowingly introduce viruses, Trojans, worms, logic bombs, keystroke loggers, spyware, adware or other material which is malicious or technologically harmful to the Website</Typography>
        </Clause>
        <Clause reference="5.9.2">
          <Typography>Impersonate any other person or fraudulently provide us with incorrect information</Typography>
        </Clause>
        <Clause reference="5.9.3">
          <Typography>Attempt to gain unauthorised access to the Website, the server on which the Website is stored or any server, computer or database connected to it</Typography>
        </Clause>
        <Clause reference="5.9.4">
          <Typography>Attack the Website via a denial-of-service attack or a distributed denial-of service attack</Typography>
        </Clause>
        <Clause reference="5.9.5">
          <Typography>Damage, disrupt or interfere with any part of the Website, any equipment or network on which the Website is stored or any software used for the provision of the Website</Typography>
        </Clause>
        <Clause reference="5.9.6">
          <Typography>Remove any copyright notice or notice of any other intellectual property right from the Website or any materials on the Website</Typography>
        </Clause>
      <Clause reference="5.10">
        <Typography>
          A breach of clause 5.9 or 5.8 may be a criminal offence under the Computer Misuse Act 1990.
          We may report any such breach to the relevant law enforcement authorities and disclose your identity to them.
          In the event of such a breach, your right to use the Website will cease immediately.
        </Typography>
      </Clause>

      <Clause reference="6">
        <Typography>Suspending or terminating your access</Typography>
      </Clause>
      <Clause>
        <Typography>We reserve the right to terminate or suspend your access to the Website immediately and without notice to you if:</Typography>
      </Clause>
        <Clause reference="6.1">
          <Typography>You breach these Terms (repeatedly or otherwise)</Typography>
        </Clause>
        <Clause reference="6.2">
          <Typography>You are impersonating any other person or entity</Typography>
        </Clause>
        <Clause reference="6.3">
          <Typography>
            When requested by us to do so, you fail to provide us within a reasonable time with sufficient information to enable us to determine the accuracy and validity of any information supplied by you, or your identity.
          </Typography>
        </Clause>
        <Clause reference="6.4">
          <Typography>We suspect you have engaged, or are about to engage, or have in any way been involved, in fraudulent, defamatory or illegal activity on the Website</Typography>
        </Clause>

      <Clause reference="7">
        <Typography>Linking to the Website</Typography>
      </Clause>
      <Clause reference="7.1">
        <Typography>You must not create a link to the Website from another website, document or any other source without first obtaining our prior written consent.</Typography>
      </Clause>
      <Clause reference="7.2">
        <Typography>Any agreed link must:</Typography>
      </Clause>
        <Clause reference="7.2.1">
          <Typography>Be to the Website&#8217;s homepage and not to any other page on the Website</Typography>
        </Clause>
        <Clause reference="7.2.2">
          <Typography>
            Be established from a website or document that is owned by you and does not contain content that is offensive, controversial, defamatory, infringes any intellectual property rights or other rights of any other person or does not comply in any way with the law in the UK and the law in any country from which they are hosted
          </Typography>
        </Clause>
        <Clause reference="7.2.3">
          <Typography>Be provided in such a way that is fair and legal and does not damage our reputation or take advantage of it</Typography>
        </Clause>
        <Clause reference="7.2.4">
          <Typography>Not suggest any form of association, approval or endorsement on our part where none exists</Typography>
        </Clause>
        <Clause reference="7.2.5">
          <Typography>Not cause the Website or content on the Website to be embedded in or &#8216;framed&#8217; by any other website</Typography>
        </Clause>
        <Clause reference="7.2.6">
          <Typography>Not cause the content of the Website to be displayed differently from the way it appears on the Website</Typography>
        </Clause>
      <Clause reference="7.3">
        <Typography>We have no obligation to inform you if the address of the Website home page changes and it is your responsibility to ensure that any link you provide to our homepage is at all times accurate.</Typography>
      </Clause>
      <Clause reference="7.4">
        <Typography>
          We reserve the right to withdraw our consent without notice and without providing any reasons for withdrawal.
          Upon receiving such notice you must immediately remove the link and inform us once this has been done.
        </Typography>
      </Clause>

      <Clause reference="8">
        <Typography>External links</Typography>
      </Clause>
      <Clause reference="8">
        <Typography>
          To provide increased value and convenience to our users, we may provide links to other websites or resources for you to access at your sole discretion and risk.
          You acknowledge and agree that, as you have chosen to enter the linked website we are not responsible for the availability of such external sites or resources, and do not review or endorse and are not responsible or liable in any way, whether directly or indirectly, for:
        </Typography>
      </Clause>
        <Clause reference="8.1">
          <Typography>The privacy practices of such websites.</Typography>
        </Clause>
        <Clause reference="8.2">
          <Typography>The content of such websites, including (without limitation) any advertising, content, products, goods or other materials or services on or available from such websites or resources.</Typography>
        </Clause>
        <Clause reference="8.3">
          <Typography>The use which others make of these websites.</Typography>
        </Clause>
        <Clause reference="8.4">
          <Typography>Any damage, loss or offence caused or alleged to be caused to you, arising from or in connection with the use of or reliance upon any such advertising, content, products, goods, materials or services available on and/or purchased by you from such external websites or resources.</Typography>
        </Clause>

      <Clause reference="9">
        <Typography>Disclaimer</Typography>
      </Clause>
        <Clause reference="9.1">
          <Typography>
            The content on the Website is provided for general information only.
            It is not intended to be advice on which you should rely.
            It shall be your responsibility to ensure that any products, services or information available through the Website meet your specific requirements.
          </Typography>
        </Clause>
        <Clause reference="9.2">
          <Typography>
            We attempt to ensure that the information available on the Website at any time is accurate.
            However, we do not guarantee the accuracy or completeness of material on this Website.
            We use all reasonable endeavours to correct errors and omissions as quickly as practicable after becoming aware or being notified of them.
            We make no commitment to ensure that such material is correct or up to date.
          </Typography>
        </Clause>
        <Clause reference="9.3">
          <Typography>
            The Website is provided on an &#8216;as is&#8217; and &#8216;as available&#8217; basis without any representation or endorsement made and we make no warranties or guarantees, whether express or implied, statutory or otherwise (unless otherwise expressly stated in these Terms or required by law).
          </Typography>
        </Clause>
        <Clause reference="9.4">
          <Typography>
            We make no representation or warranty of any kind express or implied statutory or otherwise regarding the availability of the Website or that it will be timely or error-free, that defects will be corrected, or that the Website or the server that makes it available are free of viruses or bugs.
          </Typography>
        </Clause>
        <Clause reference="9.5">
          <Typography>
            We will not be responsible or liable to you for any loss of content or material uploaded or transmitted through the Website and we accept no liability of any kind for any loss or damage from action taken in reliance on material or information contained on the Website.
          </Typography>
        </Clause>
        <Clause reference="9.6">
          <Typography>
            You are responsible for maintaining appropriate anti-virus software on and appropriately configuring the technological devices, platform and computer programs you use to access the Website that are in your control. You must bear the risk associated with the use of the internet. In particular, we will not be liable for any damage or loss caused by a distributed denial-of-service attack, any viruses, Trojans, worms, logic bombs, keystroke loggers, spyware, adware or other material which is malicious or technologically harmful that may infect your computer, peripheral computer equipment, computer programs, data or other proprietary material as a result of your use of the Website or you downloading any material posted or sold on the Website or from any website linked to it.
          </Typography>
        </Clause>

      <Clause reference="10">
        <Typography>Limitation of liability and indemnity</Typography>
      </Clause>
        <Clause reference="10.1">
          <Typography>
            If you are a consumer, nothing in these Terms affects your legal rights.
            You can obtain advice about your legal rights from Citizens Advice if you need to.
          </Typography>
        </Clause>
        <Clause reference="10.2">
          <Typography>We cannot exclude or limit our responsibility to you for:</Typography>
        </Clause>
          <Clause reference="10.2.1">
            <Typography>Death or personal injury resulting from our negligence</Typography>
          </Clause>
          <Clause reference="10.2.2">
            <Typography>Fraud or fraudulent misrepresentation</Typography>
          </Clause>
          <Clause reference="10.2.3">
            <Typography>Action pursuant to section 2(3) of the Consumer Protection Act 1987</Typography>
          </Clause>
          <Clause reference="10.2.4">
            <Typography>Any liability, right or remedy which we cannot exclude or limit pursuant to the Consumer Rights Act 2015</Typography>
          </Clause>
          <Clause reference="10.2.5">
            <Typography>Any other matter for which it would be unlawful for us to exclude or attempt to exclude our liability</Typography>
          </Clause>
        <Clause reference="10.3">
          <Typography>We will not be liable, in contract or tort (including, without limitation, negligence), or in respect of pre-contract or other representations (other than fraudulent or negligent misrepresentations) or otherwise for the below mentioned losses where they have arisen from use of or inability to use the Website for commercial purposes, or use of or reliance on any material or content displayed on the Website or on any linked websites for commercial purposes:</Typography>
        </Clause>
          <Clause reference="10.3.1">
            <Typography>Any economic losses (including without limitation loss of revenues, profits, contracts, business or anticipated savings)</Typography>
          </Clause>
          <Clause reference="10.3.2">
            <Typography>Any loss of goodwill or reputation</Typography>
          </Clause>
          <Clause reference="10.3.3">
            <Typography>Any special or indirect losses</Typography>
          </Clause>
          <Clause reference="10.3.4">
            <Typography>Any loss of data</Typography>
          </Clause>
          <Clause reference="10.3.5">
            <Typography>Wasted management or office time</Typography>
          </Clause>
          <Clause reference="10.3.6">
            <Typography>Any other loss or damage of any kind</Typography>
          </Clause>
        <Clause reference="10.4">
          <Typography>If you are not a consumer we exclude all liability, including any liability in negligence, arising from your use or inability to access or use the Website other than that referred to in clause 10.2.</Typography>
        </Clause>
        <Clause reference="10.5">
          <Typography>
            If you are a consumer, we are responsible for foreseeable loss or damage which you suffer as a result of a breach by us of the Terms or as a result of our failure to act with reasonable care and skill.
            Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen, for example, if you discussed it with us before using the Website.
            We are not responsible for unforeseeable losses.
          </Typography>
        </Clause>
        <Clause reference="10.6">
          <Typography>If you are not a consumer, you agree to fully indemnify, defend and hold us, our officers, directors, employees and suppliers, harmless immediately on demand, from and against all claims, including but not limited to losses (including loss of profit, revenue, goodwill or reputation), costs and expenses, including reasonable administrative and legal costs, arising out of any breach of these Terms by you, or any other liabilities arising out of your use of this Website or any other person accessing the Website using your personal information with your authority.</Typography>
        </Clause>
        <Clause reference="10.7">
          <Typography>If you are a consumer you agree to fully reimburse us, our officers, directors, employees and suppliers for all losses (including loss of profit, revenue, goodwill or reputation), costs and expenses, including reasonable administrative and legal costs, arising out of any breach of these Terms by you, or any other act or omission by you in using the Website or by any other person accessing the Website using your personal information with your authority that results in any legal responsibility on our part to any third party.</Typography>
        </Clause>

      <Clause reference="12">
        <Typography>Cookies</Typography>
      </Clause>
      <Clause>
        <Typography>
          This website makes use of cookies: text files containing small amounts of information which are downloaded to your device when you visit a website.
          The website can subsequently retrieve the cookie, which provides useful information.
          For more information about our cookie usage, please see our Privacy policy which can be found at {PRIVACY_POLICY_URL}.
        </Typography>
      </Clause>

      <Clause reference="13">
        <Typography>General</Typography>
      </Clause>
        <Clause reference="13.1">
          <Typography>We reserve the right to change the domain address of this Website and any services, products, product prices, product specifications and availability at any time.</Typography>
        </Clause>
        <Clause reference="13.2">
          <Typography>
            Each of the sub-clauses of these Terms should be considered separately.
            If any of these Terms are found to be unenforceable, invalid or illegal, the rest of these Terms will continue to have full effect.
          </Typography>
        </Clause>
        <Clause reference="13.3">
          <Typography>These Terms are in English only.</Typography>
        </Clause>
        <Clause reference="13.4">
          <Typography>
            We will not lose our rights under these Terms by reason of any delay by us in enforcing those rights.
            We will not be bound by any agreement not to enforce our rights under these Terms unless we have agreed not to do so in writing.
          </Typography>
        </Clause>
        <Clause reference="13.5">
          <Typography>Any delay by us in enforcing our right, non-enforcement of our rights or agreement not to enforce our rights in respect of one breach by you of these Terms will not prevent us from fully enforcing our rights in respect of any other breach by you of these Terms.</Typography>
        </Clause>

      <Clause reference="14">
        <Typography>Governing law and jurisdiction</Typography>
      </Clause>
        <Clause reference="14.1">
          <Typography>The Website is controlled and operated in the United Kingdom.</Typography>
        </Clause>
        <Clause reference="14.2">
          <Typography>
            If you are a business, these Terms are governed by the laws of England and Wales and the courts of England and Wales shall have exclusive jurisdiction over legal proceedings in connection with your use of the Website, any breach of these Terms and any question in connection with the interpretation or application of these Terms.
          </Typography>
        </Clause>
        <Clause reference="14.3">
          <Typography>
            If you are a consumer, these Terms are governed by the laws of England and Wales and you can bring legal proceedings in the courts of England and Wales.
            If you live in Scotland you can bring legal proceedings in either the courts of Scotland or England and Wales.
            If you live in Northern Ireland you can bring legal proceedings in either the courts of Northern Ireland or England and Wales.
            If you live in another country in the EU, you can bring legal proceedings in either the courts of your home country or England and Wales.
          </Typography>
        </Clause>

    </Grid>
  )
}

export default WebsiteTerms;