import React from 'react';
import {Grid, Typography} from "@mui/material";
import {colours} from "../config";

const CategoryHeader = props => {
  return (
    <Grid item xs={12}>
      <Typography
        variant="h4"
        component="h2"
        sx={{my: 3, textAlign: 'center', fontWeight: 200, textTransform: 'uppercase', color: colours.darkGrey}}
      >
        {props.children}
      </Typography>
    </Grid>
  )
}

export default CategoryHeader;